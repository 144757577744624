@media(max-width:2560px){
  nav{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: black;
  }
  nav ul{
    display: flex;
    list-style-type: none;
  }
  nav ul li{
    padding-right: 30px;
    color: white;
    font-size: 20px;
    font-weight: 600;
    cursor: pointer;
  }
  button{
    cursor: pointer;
  }
  .home{
    background-image: url('./images/backgroundImg.jpg');
    background-position-y:-400px ;
    position: fixed;
    width: 100%;
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .nrc-name{
    font-weight: 900;
  }
  .home-container{
    width: 100%;
    display: flex;
    height: 100vh;
    margin-top: 60px;
  }
  .cancel-desktop{
    display: flex;
    justify-content: flex-end;
    padding-right: 28px;
  }
  
  .cancel-desktop h2:hover{
    color: gray;
    cursor: pointer;
  }
  .info{
    color: #00f600;
    text-align: center;
    font-size: 50px;
    font-weight: 400;
  }
  .purchase-info{
    margin-top: -5px;
  }
  .purchase-container{
    display: flex;
    justify-content: space-evenly;
    width: 90%;
    margin: auto;
  }
  .purchase-container h3, .purchase-container h2{
    color: #00f600;
  }
  .left-container-purchase{
    height: 300px;
    overflow-y: scroll;
  }
  .succesfull-login{
    display: flex;
    width: 65%;
    margin: auto;
    border-radius: 20px;
    border:2px solid #00f600;
    background-color: black;
    text-align: center;
    align-items: center;
    gap: 10px;
    height: 300px;
  }
  .wel-desktop{
    border:2px solid #00f600;
    width: 70%;
    font-size: 20px;
    margin: auto;
    padding: 10px 50px;
    border-radius: 20px;
  }
  .successmark{
    color: #00f600;
    font-size: 140px;
    padding: 10px 30px 10px 20px;
    margin-left: 120px;
  }
  .successmark-email{
    color: #00f600;
    font-size: 140px;
    padding: 10px 30px 10px 20px; 
  }
  .change-password-contianer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .change-password-contianer div{
    display: flex;
    flex-direction: column;
  }
  .change-password-contianer input{
    width: 350px;
    padding: 10px 20px;
    border-radius: 10px;
    border-color: #00f600;
    background-color: transparent;
    margin-bottom: 10px;
  }
  .myaccount-cont{
    width: 80%;
    margin: auto;
    align-items: flex-start;
    display: flex;
    padding-left: 260px;
  }
  .change-password-contianer label{
    color: #00f600;
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 5px;
  }
  .wel-login{
    color: #00f600;
    font-size: 40px;
    font-weight: 100;
    margin-bottom: -5px;
  }
  .username-login{
    margin-top: 0;
    font-size: 25px;
    font-weight: 100;
    margin-bottom: 0;
  }
  .succesfull-login h5{
    color: #00f600;
  }
  .desktop-information-modal, .pruchase-desktop-modal, .shoppingChartmodal{
    top: 0;
    color: white;
    /* position: absolute; */
    width: 60%;
    margin: auto;
    margin-top: -60px;
    /* margin-left: 345px; */
    height: 700px;
    background-image: linear-gradient(black,rgba(92, 90, 90, 0.518));
    background-color: black;
    border-radius: 20px;
  }
  .ticketReciept{
    top: 0;
    left: 0;
    color: white;
    /* position: absolute; */
    padding-top: 20px;
    width: 50%;
    margin: auto;
    /* margin-top: 105px; */
    margin-left: 585px; 
    height: 720px;
    background-color: rgb(7, 7, 7);
    border-radius: 20px;
  }
  .ticketReciept_qr{
    top: 0;
    left: 0;
    color: white;
    position: absolute;
    padding-top: 20px;
    width: 40%;
    margin: auto;
    margin-top: 145px;
    margin-left: 585px; 
    height: 670px;
    background-color: rgb(7, 7, 7);
    border-radius: 20px; 
  }
  .ticket_detials{
    display: flex;
    width: 78%;
    margin: auto;
    align-items: flex-start;
    gap: 30px;
    /* padding-top: -200px; */
  }
  .ticket_detials h2{
    margin-top: -5px;
    font-weight: 500;
    font-size: 18px;
    margin-bottom: -35px;
  }
  .ticket_detials p{
    margin-bottom: -20px;
    font-size: 13px;
  }
  .tick-data{
    width: 88%;
    margin: auto;
    display: flex;
  }
  .tick-data ul {
    display: flex;
    flex-direction: column;
    gap: 14px;
    margin-top: 30px;
  }
  .tick-footer{
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    color: #00f600;margin-top: 20px;
  }
  .ticket-modal{
    margin-top: -120px;
    width: 40%;
    margin: auto;
    height: 692px; 
    border:2px solid #00f600;
    border-radius: 20px;
    background-image: linear-gradient(black,rgba(92, 90, 90, 0.518));
  }
  .your-ticket{
    display: flex;
    align-items: center;
    gap: 100px;
    color: #00f600;
  }
  .click-Qr-text{
    position: absolute;
    top: 0;
    /* left: 0; */
    transform: rotate(-90deg);
    font-weight: 400;
    /* width: 50%; */
    font-size: 24px;
    color: #00f600;
    margin-top: 225px;
    /* margin-right: -300px; */
    margin-left: -125px;
  }
  .click-Qr-text-2{
    position: absolute;
    /* top: 0; */
    /* right: 0; */
    transform: rotate(-90deg);
    font-weight: 400;
    /* width: 460px; */
    font-size: 24px;
    color: #00f600;
    margin-top: -160px;
    margin-left: -175px;
    /* padding-right: 169px;  */
  }
  .your-ticket-container{
    display: flex;
    justify-content: flex-end;
    padding:0px 25px;
  }
  .your-ticket h2{
    font-weight: 500;
  }
  .your-ticket p{
    font-size: 22px;
  }
  .cancelTicket{
    display: flex;
    justify-content: flex-end;
    font-size: 30px;
    margin-top: -10px;
    margin-right: 20px;
  }
  .qr-container{
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
  /* .qr-container div{
  width: 280px;
  height: 220px;
  overflow: auto;
  } */
  .ticket-sub-title ul li{
  font-size: 15px;
  margin-top: -15px;
  }
  .container-for-modals{
    /* margin-top: 700px; */
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.209);
    /* height: 100vh; */
    margin-top: 50%;
  }
  .shopping-main{
    top: 0;
    color: white;
   
    width: 70%;
    margin: auto;
    margin-top: -68px;
    /* margin-left: 345px; */
    height: 790px;
    background-image: linear-gradient(black,rgba(92, 90, 90, 0.518));
    background-color: black;
    border-radius: 20px; 
  }
  .shopping-title{
    margin-bottom: -18px;
  }
  .shopping-top{
    display: flex;
    align-items: center;
    text-align: center;
    margin-top: 0;
    gap: 5px;
    font-size: 19px;
    padding-inline-start: 20px;
    justify-content: center;
  }
  .shopping-top-ticket{
    display: flex;
    align-items: center;
    text-align: center;
    margin-top: 25px;
    gap: 5px;
    font-size: 19px;
    padding-inline-start: 20px;
    justify-content: center; 
  }
  .shopping-top p{
    color: #82868a;
  }
  .curr-bookin{
    display: flex;
    justify-content: space-between;
    margin-top: -10px;
  }
  .curr-bookin h2{
    color: #00f600;
    font-weight: 500;
    font-size: 28px;
  
    margin-bottom: -60px;
    /* margin-top: -20px; */
    padding-left: 30px;
  }
  .qty-booking{
    padding-left: 30px;
    display: flex;
    gap: 21px; 
    margin-top: -24px;
    margin-bottom: -30px;
  }
  .qty-booking h2{
    margin-top: -10px;
    color: #00f600;
    font-size: 24px;
    font-weight: 500;
  }
  .qty-booking-list{
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 92%;
    margin: auto;
    height: 240px;
    overflow-y: scroll;
  }
  .qty-booking-list::-webkit-scrollbar{
    background-color: transparent;
    width: 10px;
  }
  .qty-booking-list::-webkit-scrollbar-thumb{
    border-radius: 10px;
    /* color: #82868a; */
    background-color: rgb(118, 117, 117);
    /* border-radius: 5px; */
  }
  .qty-booking-list::-webkit-scrollbar-track{
    background-color: transparent;
    color: black;
  }
  .qty-booking-list-content{
    align-items: first baseline;
    margin-bottom: -45px;
    gap: 30px;
  }
  .totalprice{
    /* margin-top: -40px; */
    /* margin-top: 10px; */
    font-size: 25px;
    font-weight: 400;
    
    width: 68%;
    margin: auto;
  }
  .cont-purchase{
    width: 68%;
    margin: auto;
    text-align: center;
  }
  .cont-purchase p {
    color: #00f600;
    font-size: 25px;
    /* margin-left: 70px; */
    font-weight: 400;
    margin-bottom: 30px;
    margin-top: -56px;
  }
  .cont-purchase button{
    font-size: 20px;
    margin-top: -10px;
    margin-bottom: 15px;
  }
  .withoutreg-style{
    color: black;
    background-color: #00f600;
    width:250px ;
    font-weight: 700;
    font-size: 20px;
    padding: 15px 20px;
    border-radius: 15px;
    border: 1px solid white;
  }
  .withoutreg-style1{
    color: white;
    background-color: transparent;
    width:250px ;
    font-weight: 700;
    font-size: 20px;
    padding: 15px 20px;
    border-radius: 15px;
    border: 1px solid #00f600;
  }
  .cont-purchase button:nth-child(1){
    margin-right: 20px;
  }
  .cont-purchase-verify-btnActive{
    color: white;
    background-color: #00f600;
    width:220px ;
    font-weight: 700;
    font-size: 18px;
    padding: 15px 20px;
    border-radius: 15px;
    border: 1px solid white;
  }
  .cont-purchase-verify-btn{
    color: black;
    background-color:  #00f600;
    width:180px ;
    font-weight: 700;
    font-size: 18px;
    padding: 15px 20px;
    border-radius: 15px;
    border: 1px solid white;
  }
  .purchase-sucessfull{
    text-align: center;
  }
  .purchase-mark{
  font-size: 110px;
  color: #00f600;
  font-weight: 1200;
  margin-top: 90px;
  margin-bottom: -20px;
  }
  .thanks-for-purchase{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 50px;
    padding: 0;
    margin: 0;
  }
  .thanks-for-purchase span{
    display: flex;
    gap: 10px;
    align-items: center;
    color: #00f600;
    margin-right: 5px;
  
    margin-bottom: -110px;
   
  }
  .thanks-for-purchase span p{
    color: white;
  }
  .forwarded{
    color: #00f600;
  }
  .shoppingchartContainer{
    width: 50%;
    margin: auto;
    border: 2px solid #00f600;
    height: 380px;
    text-align: center;
    border-radius: 20px;
  }
  
  .chart-container{
    width: 70%;
    margin: auto;
    border: 2px solid #00f600;
    height: 300px;
    border-radius: 20px;
    margin-bottom: -20px;
    overflow: hidden;
  }
  .signin-desktop{
    top: 0;
    color: white;
    position: absolute;
    width: 70%;
    margin: auto;
    margin-top: -430px;
    /* margin-left: 345px; */
    height: 800px;
    background-image: linear-gradient(black,rgba(92, 90, 90, 0.518));
    background-color: black;
    border-radius: 20px;
  }
  .signin-data{
    margin-top: -45px;
  }
  .reg-email{
    margin-top: 20px;
  }
  .signin-desktop-container,.my-account, .changedata{
    width: 80%;
    margin: auto;
    border:2px solid #00f600;
    border-radius: 20px;
    padding: 10px 10px;
    text-align: center; 
  }
  .nin-verification{
    margin-top: 50px;
    width: 80%;
    margin: auto;
    border:2px solid #00f600;
    border-radius: 20px;
    padding: 10px 10px;
    text-align: center; 
  }
  .nin-container{
    display: flex;
    gap: 50px;
    /* justify-content: space-between; */
    width: 60%;
    margin: auto;
  }
  .chart-tit{
    margin-top: -60px;
  }
  .nin-details{
    /* padding-left: -50px;  */
    text-align: left;
  }
  .nin-details p{
  margin-bottom: -15px;
  font-size: 16px;
  }
  .rgister-lab{
    margin-bottom: 5px;
  }
  .signout-cont{
    display: flex;
    width: 60%;
    padding-left: 290px;
    margin: auto;
  }
   .forgotten-password-cotainer, .register-user-container, .successful-account-update,.successful-signout,.successful-nin,.failed-nin-verification, .signout{
    width: 80%;
    margin: auto;
    border:2px solid #00f600;
    border-radius: 20px;
    padding: 10px 10px;
    text-align: center;
  }
  .signout-container{
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
  }
  .signout-container button{
    color: #00f600;
    background-color: transparent;
    padding: 10px 20px;
    border: 1px solid #00f600;
    border-radius: 10px;
    margin-bottom: 30px;
    width: 300px;
  }
  .signin-desktop-container span{
    color: red;
    padding-left: 10px;
  }
  .err-msg{
    color: red;
    margin-top: 0;
    margin-bottom: 0;
    font-style: italic; 
  }
  .reg-err{
    color: red;
    margin-top: 0;
    margin-bottom: 0;
  }
  .signin-desktop-container input {
  width: 300px;
  padding: 10px 20px;
  background-color: transparent;
  border:1px solid #00f600;
  border-radius: 10px;
  /* outline: 1px solid white; */
  color: white;
  margin-bottom: -8px;
  }
  .forgotten-password-cotainer input, .shoppingchartContainer input{
    width: 300px;
    padding: 10px 20px;
    background-color: transparent;
    border:2px solid #00f600;
    border-radius: 10px;
    /* outline: 1px solid white; */
    color: white;
    margin-bottom: 80px;
  }
  .shoppingchartContainer div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .shoppingchartContainer input{
    width: 300px;
    padding: 10px 20px;
    background-color: transparent;
    border:2px solid #00f600;
    border-radius: 10px;
    color: white; 
    margin-bottom: 5px;
  }
  .shoppingchartContainer label{
    color: #00f600;
    font-weight: 700;
    font-size: 14px;
    margin-bottom: 4px;
  }
  .shoppingchartContainer h4{
    color: #00f600;
  }
  .register-container{
    /* width: 50%; */
    margin:auto;
  }
  #your-data{
  background-color: black;
  border-color: #00f600;
    color: white;
  }
  #your-nin{
    color: white;
  }
  .nin-field{
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .nin-field label{
    color: #00f600;
  }
  .nin-field input{
    width: 270px;
    padding: 10px 20px;
    background-color: transparent;
    border-color: #00f600;
    color: white;
    border-radius: 10px;
  }
  .lab-email{
    color: #00f600;
    margin-bottom: 5px;
  }
  .register-sec-1 input::-webkit-input-placeholder, .shopping-nin-container input::-webkit-input-placeholder{
    color: #00f600;
  }
  .change-password-contianer input::-webkit-input-placeholder{
    color: #00f600;
  }
  .signin-desktop-container input::-webkit-input-placeholder{
    /* color: white; */
    color: #00f600;
  }
  .forgotten-password-cotainer input::-webkit-input-placeholder,.nin-field input::-webkit-input-placeholder{
    color: #00f600;
  }
  .change-password-contianer input:focus{
    color: white;
  }
  .register-sec-1 input:focus{
    color: white;
  }
  .signin-desktop-container input:focus, .forgotten-password-cotainer input:focus, .shopping-nin-container input:focus{
    color: white;
   }
   .change-password-contianer input:not(:placeholder-shown), .nin-field input:not(:placeholder-shown), .shopping-nin-container input:not(:placeholder-shown)  {
    border-color: white;
   }
   .register-sec-1 input:not(:placeholder-shown){
    border-color: white;
   }
   .signin-desktop-container  input:not(:placeholder-shown), .forgotten-password-cotainer input:not(:placeholder-shown) {
     border-color: white;
   }
  .signin-desktop-container h5{
    font-size: 16px;
  }
  .pls-clk{
    color: #00f600;
    font-size: 14px;
  }
  .pls-clk:hover{
    text-decoration: underline;
    cursor: pointer;
  }
  .go-purchase{
    margin-top: -30px;
    font-size:115px;
    margin-bottom: 5px;
    color: green;
    cursor: pointer;
  }
  .adjuster{
    margin-bottom: 80px;
  }
  .new-Area{
    display: flex;
    gap: 50px;
    font-size: 22px;
    align-items: first baseline;
    border:1px solid #00f600;
    padding: 10px;
    height: 190px;
    border-radius: 20px;
  }
  .left-download-ticket-container{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
  }
  .left-download-ticket-container button{
    background-color: #00f600;
    color: black;
    border-radius: 10px ;
    border: 2px solid white;
    width: 100px;
    font-size: 16px;
    font-weight: 400;
    gap: 20px;
    margin-right: 20px;
    padding: 5px;
  }
  .purchase-location{
    width: 200px;
  }
  #qrCode{
  width: 80%;
  height: 290px;
  }
  #qrCode svg{
    height: 100%;
    width: 100%;
  }
  .area-right{
    display: flex;
    gap: 30px;
    font-size: 14px;
  }
  .purchase-locaion-right{
    width: 220px;
    font-size: 14px;
  }
  .go-purchase-active{
    margin-top: -30px;
    font-size:115px;
    margin-bottom: 5px;
    color:#00f600;
    cursor: pointer;
  }
  
  .go-reg{
    margin-top: -45px;
    font-size:100px;
    margin-bottom: 5px;
    color: green;
  }
  .go-reg-active{
    margin-top: -45px;
    font-size:100px;
    margin-bottom: 5px;
    color: #00f600;
  }
  #fill-data{
    color: white;
  }
  .pls-clk-go{
    font-size: 15px;
    text-decoration: underline;
    color:green;
  }
  .register-sec-1{
    display: flex;
    margin-left: 30px;
    align-items: center;
  }
  .register-sec-1 div{
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .register-sec-1 input{
    background-color: transparent;
    border-color: #00f600;
    color: white;
  }
  .title{
    width: 40px;
    padding: 10px 0px 10px 20px;
    border-radius: 10px;
    margin-right: 40px;
  }
  #title-userData{
    width: 40px;
    padding: 10px 0px 10px 20px;
    border-radius: 10px;
    margin-right: 40px;
    color: gray;
    border:1px solid gray;
  }
  #firstname-userData{
    padding: 10px 20px;
    border-radius: 10px;
    margin-right: 30px;
    border:1px solid gray;
    color: gray;
  }
  .changeData-container{
    display: flex;
    width: 80%;
    margin: auto;
    
  }
  .reg-pwd{
    width: 250px;
    padding: 10px 20px;
    margin-right: 30px;
    border-radius: 10px;
  }
  .firstname-input{
    padding: 10px 20px;
    border-radius: 10px;
    margin-right: 30px;
  }
  .state{
    border-radius: 10px;
    border-color: #00f600;
    width: 100px;
    padding: 10px 20px;
    margin-right: 30px;
  }
  .go-register{
    margin-left: -350px;
  }
  .register-option{
    padding-left: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .my-account-option{
    padding-left: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: black;
  }
  .my-account-option button{
    color: black;
    background-color: #00f600;
    font-weight: 600;
    padding: 5px 10px;
    width: 110px;
    border-radius: 10px;
    border-color: white;
    margin-bottom: 10px;
  }
  #change-password{
    color: white;
  }
  .register-option button{
    color: black;
    background-color: #00f600;
    padding: 5px 10px;
    width: 110px;
    border-radius: 10px;
    border-color: white;
    margin-bottom: 10px;
  }
  #delete-acount{
    background-color: transparent;
    border-color: red;
    color: red;
    margin-bottom: 50px;
  }
  #sign-out{
    padding: 12px 0px;
  }
  #sign-out-sign-out{
    padding: 12px 0px;
    color: white; 
  }
  .telphone{
    border-radius: 10px;
    border-color: #00f600;
    width: 170px;
    padding: 10px 20px;
    margin-right: 30px;
    /* margin-left: 20px; */
  }
  .title-label{
    margin-left: -40px;
    color: #00f600;
  }
  .title-label-userData{
    margin-left: -40px;
    color: gray;
  }
  .cont-1, .cont-2{
    width: 50%;
    height: 700px;
  }
  .cont-1{
    text-align: center;
    color: white;
  }
  .cont-1-sec{
    width: 100%;
    margin: auto;
    margin-left: 150px;
  }
  .cont-1-sec h2{
    font-size: 50px;
    font-weight: 500;
  }
  .cont-1-sec h3{
    margin-top: -19px;
    font-size: 120px;
    font-weight: 500;
    color: #00f600;
    margin-bottom: -38px;
  }
  /* .failed-nin-verification{
    position: absolute;
    top: 21%;
    background-color: rgba(0, 0, 0, 0.848);
    width: 80%;
    height: 565px;
  } */
  .successful-password{
    position: absolute;
    top: 21%;
    background-color: rgba(0, 0, 0, 0.848);
    width: 80%;
    height: 480px;
  }
  .failed-container{
    padding-top: 55px;
    align-items: center;
    width: 65%;
    margin: auto;
    display: flex;
    gap: 120px;
  }
  .successful-nin-container{
    align-items: center;
    width: 65%;
    margin: auto;
    display: flex;
    gap: 100px;
  }
  .successful-signout-user{
    display: flex;
    width: 50%;
    gap: 60px;
    margin: auto;
  }
  .successful-signout-user h3{
    margin-bottom: 60px;
  }
  .successful-signout-user p{
    color: #00f600;
  }
  .successful-nin-container h3{
    font-size: 29px;
    font-weight: 100;
  }
  .successful-nin-container p{
    color: #00f600;
  }
  .failed-sign{
    color: red;
    font-size: 90px;
    margin-top: 140px;
    font-weight: 400;
  }
  .successful-sign, .successful-sign-pwd{
    color: #00f600;
    font-size: 120px;
    margin-top: 140px;
    font-weight: 900;
  }
  .successful-sign-out{
    color: #00f600;
    font-size: 120px;
    margin-top: 70px;
    font-weight: 900; 
  }
  .please-check{
    color: #00f600;
    font-weight: 100;
    font-size: 70px;
    margin-bottom: -25px;
  }
  
  .failed-container h3{
    color: red;
    font-size:25px;
    font-weight: 100;
  }
  .failed-container p{
    color:#00f600;
  }
  .nrc{
    color: #00f600;
    font-size: 17.5px;
    margin-left: 2px;
  }
  .welcome{
    /* margin-top: -10px; */
    color: #00f600;
    font-weight: 100;
    font-size: 65px;
    margin-bottom: -10px;
  }
  .content{
    width: 45%;
    margin: auto;
    border: 10px solid black;
    background-color: #1f2e3d;
    height: 600px;
    border-radius: 20px;
    text-align: center;
    color: white;
    padding-top: 60px;
  }
  .booking-home-dkt{
    margin-top: -65px;
    display: flex;
    gap: 15px;
    justify-content: center;
    color: #82868a;
    font-size: 20px;
  }
  .booking-container{
   display: flex;
   flex-direction: column;
   padding: 5px 20px;
    width: 80%;
    margin: auto;
    border:2px solid #00f600;
    border-radius: 20px;
    height: 390px;
    text-align: center;
    background-image: linear-gradient(black, rgba(255, 255, 255, 0.091));
    margin-bottom: 10px;
  }
  .booking-container p{
   margin-top: -20px;
  }
  .booking-dkt{
    color: white;
  }
  .choose-time{
    /* width: 250px; */
    padding: 10px 35px;
    font-size: 22px;
    background-color: #00f600;
    color: black;
    border-radius: 10px;
    cursor: pointer;
    margin-bottom: -5px;
  }
  .choose-time:hover{
    background-color: #04d604;
  }
  .choose-time-2{
    padding: 10px 35px;
    font-size: 22px;
    background-color: #00f600;
    color: white;
    border-radius: 10px;
  }
  .choose-time-title{
    display: flex;
    flex-direction: row;
    gap: 30px;
    color: #00f600;
  
    padding-left: 10px;
    font-size: 11px;
    font-weight: 700;
  }
  .pick-tim{
    display:flex;
    align-items:center;
  }
  .select-passenger{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 30px;
    /* color:; */
    background-color: rgb(16, 67, 16);
    color: black;
    border-radius: 10px;
  }
  .select-passenger p{
    margin-top: 5px;
    margin-bottom: 10px;
    font-size: 19px;
    font-weight: 600;
  }
  .select-passenger-active{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 30px;
    background-color: #00f600;
    color: white;
    border-radius: 10px;
    border: 1px solid white;
  }
  .select-passenger-active p{
    margin-top: 5px;
    margin-bottom: 10px;
    font-size: 19px;
    font-weight: 600;
  }
  .flex{
    display: flex;
  }
  .add-to-chart{
    /* margin: 2px -10px; */
    padding: 0px 10px 6px 10px;
    margin-right: 20px;
    font-size: 25px;
    border:2px solid #00f600;
    border-radius: 60%;
    color: #00f600;
  }
  .travel-time{
    align-items: center;
    gap: 25px;
    font-size: 12px;
    /* padding-left: 10px; */
    margin-bottom: -35px;
  }
  .travel-time-cont{
    border:2px solid #00f600;
    padding: 5px;
    gap: 20px;
    align-items: center;
    border-radius: 10px;
    margin-bottom: 10px;
  }
  .travel-time-cont button:nth-child(1){
    background-color: #76d6fa;
    height: 40px;
    padding: 0px 10px;
    cursor: pointer;
    border: none;
    margin-right: 5px;
    border-radius: 5px;
  }
  
  .travel-time-cont button:nth-child(2){
    background-color: #eb58ed;
    height: 40px;
    padding: 0px 10px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
  }
  .travel-arrow p{
    padding-top: 10px;
    color: #00f600;
    /* font-size: 178px; */
    font-weight: 10;
    margin-bottom: -5px;
    /* width: 150px; */
  }
  .hide{
    display: none;
  }
  .tim-class{
    color: #00f600;
    font-weight: 750;
    margin-top: -10px;
    margin-bottom: 4px;
    font-size:30px;
  }
  .tim-class-white{
    color: white;
    font-weight: 750;
    margin-top: -10px;
    margin-bottom: 4px;
    font-size:30px; 
  }
  .tim-class-1{
    color: #00f600;
   font-weight:700;
   margin-top:15px;
    font-size:35px;
    /* font-weight: 900; */
    margin-bottom: 4px;
  }
  .tim-class-3{
    color: rgb(16, 67, 16);
    font-weight:700;
    margin-top:15px;
     font-size:35px;
    /* font-weight: 750; */
    margin-bottom: 4px; 
  }
  .choose-time-container{
    /* padding-top: 20px; */
    padding: 5px 10px;
    background-color: black;
    position: absolute;
    width: 350px;
    margin: auto;
    margin-top: 230px;
    margin-left: -14px;
    z-index: 10000;
    height: 120px;
   
    border: 2px solid white;
    border-radius: 20px;
  }
  .container-list-trv{
    overflow-y: scroll;
    height: 80px;
  }
  .container-list-trv::-webkit-scrollbar{
    width: 5px;
   
  }
  .container-list-trv::-webkit-scrollbar-track{
    color:white ;
  }
  .container-list-trv::-webkit-scrollbar-thumb{
    background-color: rgb(118, 117, 117);
    border-radius: 5px;
    height: 30px;
  }
  .choose-time-container p{
    margin-top: 10px;
  }
  .select-trip{
    font-size: 28.5px;
    -ms-text-kashida-space: 50px;
  }
  .From{
    background-color: #00f600;
    appearance: none;
    text-align: center;
    cursor: pointer;
    color: black;
    /* user-select: none; */
    font-size: 50px;
    text-transform:uppercase;
    font-weight: 650;
    /* font-family: Montserrat; */
    padding: 4px 10px;
    /* padding: 25px; */
    margin-bottom: 20px;
    border: 1px solid white;
    border-radius: 10px;
    /* color: greenyellow; */
    
  }
  
  
  .dropdownContent{
    position: absolute;
    background-color: black;
    color: #00f600;
    width: 305px;
    margin-left: -9px;
    border-radius: 10px;
    padding: 10px;
    font-size: 28px;
    height: 220px;
    overflow-y: scroll;
    scroll-margin-right: -10px;
    scrollbar-width:thin;
    scrollbar-gutter: black;
    scrollbar-track-color:black;
    scrollbar-base-color: black;
    /* scroll-snap-type: none; */
  }
  .dropdownContent::-webkit-scrollbar{
    background-color: black;
    scroll-margin-right: -10px;
    scrollbar-width:thin;
    scrollbar-track-color:black;
    scrollbar-base-color: black;
    scroll-snap-type: none;
    color: black;
  }
  .dropdownContent::-webkit-scrollbar-track{
    background-color: black;
    color: black;
  }
  .dropdownItem{
    /* padding: 10px; */
    padding-bottom: 10px;
    cursor: pointer;
    /* transition: all 0.2s; */
  }
  .dropdownItem:hover{
  border:1px solid #00f600;
  border-radius: 10px;
  /* padding: 10px; */
  }
  .From select option:focus-visible{
  
  background-color: green;
  border: 1px solid #00f600;
  }
  select option{
    font-size: 14px;
  }
  
  .selection{
    display: flex;
    flex-direction: column;
    width: 75%;
    margin: auto;
    margin-top: 40px;
  }


  .each-passenger-container{
    width: 85%;
    margin: auto;
    border: 2px solid #00f600;
    height: 60%;
    border-radius: 40px;
  }
  .header-for-each{
    font-size: 14px;
    text-align: center;
  }
  .header-for-each p {
    color: #00f600;
    font-size: 20px;
    font-weight: 500;
    margin-top: -10px;
  }
  .booking-for-each-passenger{
    width: 90%;
    margin: auto;
    margin-top: -30px;
  }



  .date{
    appearance: none;
    text-align: center;
    text-transform: uppercase;
    font-weight: 650;
    font-size: 50px;
    cursor: pointer;
    color: black;
    background-color: #00f600;
    border: 1px solid white;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 10px;
  }
  
  .date-update{
    appearance: none;
    text-align: center;
    text-transform: uppercase;
    font-weight: 530;
    font-size: 50px;
    color: white;
    background-color:black;
    border: 1px solid #00f600;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 10px; 
  }
  input[type="date"]{
    background-color: #00f600;
    font-weight: 400;
    /* -webkit-calendar-picker-indicator:dis */
  }
  input[type="date"]::before{
   background-color: black;
   color: white;
    /* -webkit-calendar-picker-indicator:dis */
  }
  input[type="date"]::-webkit-calendar-picker-indicator {
    /* display: none; */
    color: white;}
  input[type="date"]:focus{
    background-color: #00f600;
    color: white;
    /* -webkit-calendar-picker-indicator:dis */
  }
  
  /* input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    background-color: greenyellow;
  } */
  .date::placeholder{
    color: black;
    font-weight: 550;
  }
  /* input[type="date"]::-webkit-datetime-edit-text{
    display: none;
    -webkit-ap
  } */
  .payment_procedure{
    width: 80%;
    margin: auto;
    margin-top: 40px;
   
    border:2px solid #00f600;
    border-radius: 20px;
    height: 600px;
    padding: 20px;
  }
  .payment_procedure form{
    width: 60%;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
  .payment_procedure form div{
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .payment_procedure input{
    background-color: transparent;
    border:2px solid #00f600;
    padding: 20px;
    border-radius: 15px;
    outline: none;
    color: white;
  }
  .payoutGoBtn{
    padding-top: 40px;
  }
  #payment-submit-btn{
    margin-top: 40px;
    font-size: 20px;
    width:140px;
  }
  .sect-go-text{
    color: rgb(2, 84, 2);
    margin-top: -5px;
    /* margin-bottom: ; */
  }

  .sect-go{
    color: rgb(3, 33, 3);
    /* margin-top: -42px; */
    font-size: 10px;
    font-weight: bolder;
    /* font-family: Montserrat; */
    cursor: pointer;
  }
  .sect-go1{
    margin-top: -40px;
    color: rgba(7, 69, 5, 0.597);
    font-size: 100px;
    margin-top: -40px;
    cursor: pointer;
  }
  .sect-goCart{
    margin-top: -30px;
    font-size: 150px;
    font-weight: 900;
    cursor: pointer; 
    color:  rgb(16, 67, 16);
  }
  .sect-go2{
    color: #00f600;
    margin-top: -40px;
    margin-top: -40px;
    font-size: 100px;
    cursor: pointer;
  }
  .sect-goactive{
    margin-top: -52px;
    font-size: 150px;
    font-weight: 900;
    cursor: pointer; 
  
    color: #00f600;
  }
  .sect-goactive-buy{
    margin-top: -30px;
    font-size: 150px;
    font-weight: 900;
    cursor: pointer; 
  
    color: #00f600;
  }
  
  #go{
    margin-top: -42px;
    font-size: 150px;
  }
  #home-go{
    margin-top: -42px;
    font-size: 140px;
    cursor: pointer;
  }
  #bookingGO{
    margin-top: -52px;
    font-size: 150px;
    font-weight: 900;
    cursor: pointer; 
    color:  rgb(16, 67, 16);
  }
  #goBooking{
    margin-top: -52px;
    font-size: 150px;
    font-weight: 900;
    cursor: pointer; 
    color:  rgb(16, 67, 16);
  }
  #goBookingActive{
    margin-top: -52px;
    font-size: 150px;
    font-weight: 900;
    cursor: pointer; 
    color: #00f600;  
  }
  #bookingGO1{
    margin-top: -52px;
    font-size: 150px;
    font-weight: 900;
    cursor: pointer; 
    color: #00f600; 
  }
  footer{
    position: absolute;
    width: 100%;
    font-size: 20px;
    /* line */
    color: white;
    bottom: 0;
    margin-bottom: 20px;
    text-align: center;
  }
  .wait-container{
    width: 55%;
    margin:auto;
    height: 650px;
    border: 2px solid #00f600;
    border-radius: 20px;
    background-color: black;
  }
  .please-wait{
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 48px;
    font-weight: 100;
  }
  .please-wait span{
    color: #00f600;
    margin-left: 5px;
  }
  .processing-order{
    width: 55%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #00f600;
    font-size: 26px;
    border-radius: 50%;
    border: 2px solid #00f600;
    height: 400px;
  }
  /* mobile */
  .mobileNav{
    display: flex;
    justify-content: space-between;
    background-color: transparent;
    align-items: flex-end;
  }
  .hamburger{
    color: #00f600;
    font-size: 40px;
  }
  .headername{
    color: #00f600;
    font-size: 40px;
  }
  .shopping-cart-mb{
    position: absolute;
    top: 0;
    left: 0;
    width: 80%;
    border-radius: 10px;
    background-image: linear-gradient(black,rgb(83, 81, 81));
    color: white;
    border:2px solid #00f600; 
    padding: 5px 20px;
    margin-top: 10px;
  }
  .shopping-cart-mb div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
  }
  .shopping-cart-mb h4, .information-mb h4, .sign-in-mb h4, .purchase-mb h4{
    color: #00f600;
  }
  .shopping-cart-mb h3, .information-mb h3, .sign-in-mb h3, .purchase-mb h3{
    cursor: pointer;
    color: #82868a;
  }
  .information-mb{
    position: absolute;
    top: 0;
    left: 0;
    width: 80%;
    border-radius: 10px;
    background-image: linear-gradient(black,rgb(83, 81, 81));
    color: white;
    border:2px solid #00f600; 
    padding: 5px 20px;
    margin-top: 10px; 
  }
  .information-mb div, .sign-in-mb div, .purchase-mb div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
  }
  .purchase-mb{
    position: absolute;
    top: 0;
    left: 0;
    width: 80%;
    border-radius: 10px;
    background-image: linear-gradient(black,rgb(83, 81, 81));
    color: white;
    border:2px solid #00f600; 
    padding: 5px 20px;
    margin-top: 10px; 
    padding-bottom: 20px; 
  }
  .sign-in-mb{
    position: absolute;
    top: 0;
    left: 0;
    width: 80%;
    border-radius: 10px;
    background-image: linear-gradient(black,rgb(83, 81, 81));
    color: white;
    border:2px solid #00f600; 
    padding: 5px 20px;
    margin-top: 10px; 
    padding-bottom: 20px;
  }
  .sign-in-mb-form{
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
  }
  .sign-in-mb-form div{
    display: block;
  }
  .sign-in-mb-form label{
    font-size: 11px;
    font-weight: 700;
    color: #00f600;
  }
  .pls-sign-in-mb{
    margin-top: -10px;
  }
  .sign-in-mb-form input{
    width: 85%;
    padding: 10px 20px;
    border-radius: 7px;
    background-color: #d8dbdd;
  }
  .register-mb{
    width: 100%;
    text-align: center;
    color: black;
    background-color: #00f600;
    padding: 10px;
    font-weight: 500;
    font-size: 18px;
    border-radius: 8px;
  }
  .sign-in-mb-form button{
    margin-top: 10px;
    color: black;
    background-color: #00f600;
    width: 40%;
    padding: 10px;
    border-radius: 8px;
  }
  .active{
    color: #00f600;
  }
  .please-go-desk{
    color: green;
    text-align: center;
  }
  .select-trip-go-container{
    display: flex;
    width: 76%;
    margin: auto;
    gap: 10px;
  }
  .go-shopping-chart{
    display: flex;
    justify-content: center;
    gap: 160px;
  }
  .go-payout{
    display: flex;
    justify-content: center;
    gap: 100px;
  }
  .go-shopping-chart button, .select-trip-go-container button, .go-payout button{
    height: 30px;
    padding:  6px 20px 4px 23px;
    background-color: transparent;
    color: rgb(16, 67, 16);
    margin-top: 55px;
    border-radius: 20px;
    border: 2px solid rgb(16, 67, 16);
    /* margin-top: 10px;  */
  }
  #prev-btn-shopping-chart{
    color: #00f600;
    border-color: #00f600;
    /* margin-top: 45px; */
  }

  .trip-title{
    font-size:30px;
     margin-top:10px;
     margin-bottom: 15px;
    font-weight:700;
  }
  .from-to-booking{
    font-size:22px;
    margin-top:-28px;
    margin-bottom:25px;
  }
  .bookingDate{
    font-size:30px;
     margin-top:-20px;
     margin-bottom: 15px;
     font-weight:700;
  }
.booking-newDate{
  font-size:22px;
  margin-top:-35px;
}




  





  .nav-modal{
    background-image: linear-gradient(black,rgb(83, 81, 81));
    color: white;
    border:2px solid #00f600;
    height: 300px;
    width: 220px;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1000;
    padding-left: -10px;
    margin-right: -30px;
    border-radius: 10px;
  }
  .nav-modal ul{
    margin-left: -25px;
    display: flex;
    flex-direction: column;
    list-style-type: none;
    font-size: 18px;
  }
  .nav-modal ul li{
    margin-bottom: 20px;
  }
  .mobileHome{
    background-image: url('./images/background.png');
    width: 100%;
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .headings{
    margin-top: -15px;
    text-align: center;
    color: white;
  }
  .titl-1, .titl-2{
    font-size: 18px;
  }
  .titl-2{
    color: #00f600;
    margin-bottom: -10px;
  }
  .content-mb{
    color: white;
  }
  .content-mb-container {
    margin-top: 40px;
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  }
  .sub-content-mobile{
    width: 90%;
    margin-top: auto;
  }
  .select-trip-mb{
    text-align: center;
    font-size: 18px;
  }
  .form-mb{
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: auto;
  }
  .date-mb{
    appearance: none;
    text-align: center;
    text-transform: uppercase;
    font-weight: 530;
    font-size: 40px;
    color: black;
    background-color: #00f600;
    border: 1px solid white;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
  }
  .sel-mb{
    margin-top: -10px;
  }
  #go-mb{
    margin-top: -42px;
    font-size: 160px;
  }
  .go-options{
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    width: 100%;
    margin:auto;
  }
  .go-options button{
    height: 30px;
    padding:  6px 20px 4px 23px;
    background-color: transparent;
    color: green;
    border-radius: 20px;
    border: 2px solid green;
    margin-top: 20px;
  }
  .mb-footer{
    font-size: 14px;
    margin-bottom: 5px;
  }
  .booking-mb{
    width: 85%;
    margin: auto;
  }
  .booking-mb-title{
    display: flex;
    align-items: center;
    color: #82868a;
    margin-bottom: -10px;
   
  }
  .booking-mb-title p{
    padding-right: 5px;
  }
  .arrow-left{
    font-size: 14px;
    margin-top: 10px;
  }
  .booking-mb-container{
    background-image: linear-gradient(black,rgb(83, 81, 81));
    text-align: center;
    color: white;
   border-radius: 15px;
    width: 100%;
    margin: auto;
    border:2px solid #00f600;
    height: 300px;
  }
  .booking-bm-go{
    text-align: center;
  }
  .mb-trip{
    margin-top: 4px;
    margin-bottom: -10px;
    font-weight: 400;
    font-size: 25px;
  }
  .mb-date{
    margin-top: -15px;
    margin-bottom: -10px;
  }
  .time-class-mb{
    display: flex;
    flex-direction: column;
  }
  .time-class-mb button{
    width: 90%;
    margin: auto;
    padding: 15px;
    font-size: 18px;
    border-radius: 10px;
    background-color: #00f600;
    /* margin-bottom: 20px; */
  }
  .time-class-mb-table{
    display: none;
    background-color: white;
    color: black;
    width: 75%;
    position: absolute;
    margin:auto;
    margin-left: 20px;
    margin-top: 65px;
  }
  .time-class-mb-table th{
    width: 70px;
  }
  .mb-trip-detail{
    width: 30%;
    margin: auto;
    display:flex;
     gap:10px;
      text-align:center;
  }
  .add-passagers-mb{
    display: flex;
    justify-content: space-between;
    color: black;
    width: 75%;
    margin: auto;
    align-items: center;
    background-color: green;
    padding: 5px 20px;
    border-radius: 10px;
  }
  .add-passagers-mb div{
    font-size: 22px;
    font-weight: 700;
  }
  .increase-btn, .decrease-btn{
    padding: 4px 10px;
    border-radius: 50%;
  }
  .increase-btn:hover, .decrease-btn:hover{
    font-size: 20px;
    color: white;
    background-color: #00f600;
  }
  .go-options .prev-btn-mb{
    border:2px solid #00f600;
    color: #00f600;
  }
  .go-options .prev-btn-mb:hover{
    border:2px solid white;
    background-color: #00f600;
    color: black;
  }
  .type-sec{
    font-size:35px;
     font-weight:700;
     margin-top:5px;
  }
  .setting-policy{
    font-size:20px;
    margin-top:-85px;
  }
  .to-proceed{
    font-size:14px;
    margin-top:5px;
     margin-bottom :-10px;
  }
  .miniGO{
    font-size:12px;
    margin-top:5px;
     margin-bottom:-10px;
      color:#00f600

  }
  .totalprice-buyTicket{
    font-size: 25px;
  }
  .totalprice-buyTicket span{
    margin-left: -2px;
  
  }
  .totalpriceP{
    margin-left:20px;
  }
  .totalprice-buyTicket-p{
    margin-left:20px;
  }


}

@media(max-width:1750px){
  nav{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: black;
  }
  nav ul{
    display: flex;
    list-style-type: none;
  }
  nav ul li{
    padding-right: 30px;
    color: white;
    font-size: 20px;
    font-weight: 600;
    cursor: pointer;
  }
  button{
    cursor: pointer;
  }
  .home{
    background-image: url('./images/backgroundImg.jpg');
    background-position-y:-200px ;
    position: fixed;
    width: 100%;
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .nrc-name{
    font-weight: 900;
  }
  .home-container{
    width: 100%;
    display: flex;
    height: 100vh;
    margin-top: 60px;
  }
  .cancel-desktop{
    display: flex;
    justify-content: flex-end;
    padding-right: 28px;
  }
  
  .cancel-desktop h2:hover{
    color: gray;
    cursor: pointer;
  }
  .trip-title{
    font-size:20px;
     margin-top:10px;
    font-weight:700;
  }
  .from-to-booking{
    font-size:18px;
    margin-top:-28px;
    margin-bottom:30px;
  }
  .bookingDate{
    font-size:20px;
     margin-top:-20px;
     font-weight:700;
  }
.booking-newDate{
  font-size:18px;
  margin-top:-35px;
}

  .info{
    color: #00f600;
    text-align: center;
    font-size: 50px;
    font-weight: 400;
  }
  .purchase-info{
    margin-top: -5px;
  }
  .purchase-container{
    display: flex;
    justify-content: space-evenly;
    width: 90%;
    margin: auto;
  }
  .purchase-container h3, .purchase-container h2{
    color: #00f600;
  }
  .left-container-purchase{
    height: 300px;
    overflow-y: scroll;
  }
  .succesfull-login{
    display: flex;
    width: 65%;
    margin: auto;
    border-radius: 20px;
    border:2px solid #00f600;
    background-color: black;
    text-align: center;
    align-items: center;
    gap: 10px;
    height: 300px;
  }
  .wel-desktop{
    border:2px solid #00f600;
    width: 70%;
    font-size: 20px;
    margin: auto;
    padding: 10px 50px;
    border-radius: 20px;
  }
  .successmark{
    color: #00f600;
    font-size: 140px;
    padding: 10px 30px 10px 20px;
    margin-left: 120px;
  }
  .successmark-email{
    color: #00f600;
    font-size: 140px;
    padding: 10px 30px 10px 20px; 
  }
  .change-password-contianer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .change-password-contianer div{
    display: flex;
    flex-direction: column;
  }
  .change-password-contianer input{
    width: 350px;
    padding: 10px 20px;
    border-radius: 10px;
    border-color: #00f600;
    background-color: transparent;
    margin-bottom: 10px;
  }
  .myaccount-cont{
    width: 80%;
    margin: auto;
    align-items: flex-start;
    display: flex;
    padding-left: 260px;
  }
  .change-password-contianer label{
    color: #00f600;
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 5px;
  }
  .wel-login{
    color: #00f600;
    font-size: 40px;
    font-weight: 100;
    margin-bottom: -5px;
  }
  .username-login{
    margin-top: 0;
    font-size: 25px;
    font-weight: 100;
    margin-bottom: 0;
  }
  .succesfull-login h5{
    color: #00f600;
  }
  .desktop-information-modal, .pruchase-desktop-modal, .shoppingChartmodal{
    top: 0;
    color: white;
    /* position: absolute; */
    width: 80%;
    margin: auto;
    margin-top: -60px;
    /* margin-left: 345px; */
    height: 700px;
    background-image: linear-gradient(black,rgba(92, 90, 90, 0.518));
    background-color: black;
    border-radius: 20px;
  }
  .ticketReciept{
    top: 0;
    left: 0;
    color: white;
    /* position: absolute; */
    padding-top: 20px;
    width: 50%;
    margin: auto;
    /* margin-top: 105px; */
    /* margin-left: 585px;  */
    height: 720px;
    background-color: rgb(7, 7, 7);
    border-radius: 20px;
  }
  .ticketReciept_qr{
    top: 0;
    left: 0;
    color: white;
    position: absolute;
    padding-top: 20px;
    width: 40%;
    margin: auto;
    margin-top: 145px;
    margin-left: 585px; 
    height: 670px;
    background-color: rgb(7, 7, 7);
    border-radius: 20px; 
  }
  .ticket_detials{
    display: flex;
    width: 78%;
    margin: auto;
    align-items: flex-start;
    gap: 30px;
    /* padding-top: -200px; */
  }
  .ticket_detials h2{
    margin-top: -5px;
    font-weight: 500;
    font-size: 18px;
    margin-bottom: -35px;
  }
  .ticket_detials p{
    margin-bottom: -20px;
    font-size: 13px;
  }
  .tick-data{
    width: 88%;
    margin: auto;
    display: flex;
  }
  .tick-data ul {
    display: flex;
    flex-direction: column;
    gap: 14px;
    margin-top: 30px;
  }
  .tick-footer{
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    color: #00f600;margin-top: 20px;
  }
  .ticket-modal{
    margin-top: -120px;
    width: 50%;
    margin: auto;
    height: 692px; 
    border:2px solid #00f600;
    border-radius: 20px;
    background-image: linear-gradient(black,rgba(92, 90, 90, 0.518));
  }
  .your-ticket{
    display: flex;
    align-items: center;
    gap: 100px;
    color: #00f600;
  }
  .click-Qr-text{
    position: absolute;
    top: 0;
    /* left: 0; */
    transform: rotate(-90deg);
    font-weight: 400;
    /* width: 50%; */
    font-size: 24px;
    color: #00f600;
    margin-top: 225px;
    /* margin-right: -300px; */
    margin-left: -125px;
  }
  .click-Qr-text-2{
    position: absolute;
    /* top: 0; */
    /* right: 0; */
    transform: rotate(-90deg);
    font-weight: 400;
    /* width: 460px; */
    font-size: 24px;
    color: #00f600;
    margin-top: -160px;
    margin-left: -175px;
    /* padding-right: 169px;  */
  }
  .your-ticket-container{
    display: flex;
    justify-content: flex-end;
    padding:0px 25px;
  }
  .your-ticket h2{
    font-weight: 500;
  }
  .your-ticket p{
    font-size: 22px;
  }
  .cancelTicket{
    display: flex;
    justify-content: flex-end;
    font-size: 30px;
    margin-top: -10px;
    margin-right: 20px;
  }
  .qr-container{
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
  /* .qr-container div{
  width: 280px;
  height: 220px;
  overflow: auto;
  } */
  .ticket-sub-title ul li{
  font-size: 15px;
  margin-top: -15px;
  }
  .container-for-modals{
    /* margin-top: 700px; */
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* height: 100vh; */
    margin-top: 50%;
  }
  .shopping-main{
    top: 0;
    color: white;
   
    width: 80%;
    margin: auto;
    margin-top: 18px;
    /* margin-left: 345px; */
    height: 730px;
    background-image: linear-gradient(black,rgba(92, 90, 90, 0.518));
    background-color: black;
    border-radius: 20px; 
  }
  .shopping-title{
    margin-bottom: -18px;
  }
  .shopping-top{
    display: flex;
    align-items: center;
    text-align: center;
    margin-top: 0;
    gap: 5px;
    font-size: 19px;
    padding-inline-start: 20px;
    justify-content: center;
  }
  .shopping-top-ticket{
    display: flex;
    align-items: center;
    text-align: center;
    margin-top: 25px;
    gap: 5px;
    font-size: 19px;
    padding-inline-start: 20px;
    justify-content: center; 
  }
  .shopping-top p{
    color: #82868a;
  }
  .curr-bookin{
    display: flex;
    justify-content: space-between;
    margin-top: -10px;
  }
  .curr-bookin h2{
    color: #00f600;
    font-weight: 500;
    font-size: 28px;
  
    margin-bottom: -60px;
    /* margin-top: -20px; */
    padding-left: 30px;
  }
  .qty-booking{
    padding-left: 30px;
    display: flex;
    gap: 21px; 
    margin-top: -24px;
    margin-bottom: -30px;
  }
  .qty-booking h2{
    margin-top: -10px;
    color: #00f600;
    font-size: 24px;
    font-weight: 500;
  }
  .qty-booking-list{
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 92%;
    margin: auto;
    height: 240px;
    overflow-y: scroll;
  }
  .qty-booking-list::-webkit-scrollbar{
    background-color: transparent;
    width: 10px;
  }
  .qty-booking-list::-webkit-scrollbar-thumb{
    border-radius: 10px;
    /* color: #82868a; */
    background-color: rgb(118, 117, 117);
    /* border-radius: 5px; */
  }
  .qty-booking-list::-webkit-scrollbar-track{
    background-color: transparent;
    color: black;
  }
  .qty-booking-list-content{
    align-items: first baseline;
    margin-bottom: -45px;
    gap: 30px;
  }
  .totalprice{
    /* margin-top: -40px; */
    /* margin-top: 10px; */
    font-size: 25px;
    font-weight: 400;
    
    width: 68%;
    margin: auto;
  }
  .totalprice-buyTicket{
    font-size: 22px;
    font-weight: 400;
    width: 68%;
    margin: auto;
    margin-top: 30px;
  }
  .totalprice-buyTicket span{
    font-size: 22px;
  }
  .cont-purchase{
    width: 68%;
    margin: auto;
    text-align: center;
  }
  .cont-purchase p {
    color: #00f600;
    font-size: 25px;
    /* margin-left: 70px; */
    font-weight: 400;
    margin-bottom: 30px;
    margin-top: -56px;
  }
  .cont-purchase button{
    font-size: 20px;
    margin-top: -10px;
    margin-bottom: 15px;
  }
  .withoutreg-style{
    color: black;
    background-color: #00f600;
    width:250px ;
    font-weight: 700;
    font-size: 20px;
    padding: 15px 20px;
    border-radius: 15px;
    border: 1px solid white;
  }
  .withoutreg-style1{
    color: white;
    background-color: transparent;
    width:250px ;
    font-weight: 700;
    font-size: 20px;
    padding: 15px 20px;
    border-radius: 15px;
    border: 1px solid #00f600;
  }
  .cont-purchase button:nth-child(1){
    margin-right: 20px;
  }
  .cont-purchase-verify p{
    margin-top: -56px;
    padding-left: 40px;
  }
  .cont-purchase-verify-btnActive{
    color: white;
    background-color: #00f600;
    width:220px ;
    font-weight: 700;
    font-size: 18px;
    padding: 15px 20px;
    border-radius: 15px;
    border: 1px solid white;
  }
  .cont-purchase-verify-btn{
    color: black;
    background-color:  #00f600;
    width:180px ;
    font-weight: 700;
    font-size: 18px;
    padding: 15px 20px;
    border-radius: 15px;
    border: 1px solid white;
  }
  .purchase-sucessfull{
    text-align: center;
  }
  .purchase-mark{
  font-size: 110px;
  color: #00f600;
  font-weight: 1200;
  margin-top: 90px;
  margin-bottom: -20px;
  }
  .thanks-for-purchase{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 50px;
    padding: 0;
    margin: 0;
  }
  .thanks-for-purchase span{
    display: flex;
    gap: 10px;
    align-items: center;
    color: #00f600;
    margin-right: 5px;
  
    margin-bottom: -110px;
   
  }
  .thanks-for-purchase span p{
    color: white;
  }
  .forwarded{
    color: #00f600;
  }
  .shoppingchartContainer{
    width: 50%;
    margin: auto;
    border: 2px solid #00f600;
    height: 380px;
    text-align: center;
    border-radius: 20px;
  }
  
  .chart-container{
    width: 70%;
    margin: auto;
    border: 2px solid #00f600;
    height: 300px;
    border-radius: 20px;
    margin-bottom: -20px;
    overflow: hidden;
  }
  .signin-desktop{
    top: 0;
    color: white;
    position: absolute;
    width: 70%;
    margin: auto;
    margin-top: -320px;
    height: 680px;
    background-image: linear-gradient(black,rgba(92, 90, 90, 0.518));
    background-color: black;
    border-radius: 20px;
  }
  .signin-data{
    margin-top: -65px;
  }
  .reg-email{
    margin-top: 20px;
  }
  .signin-desktop-container{
    width: 80%;
    margin: auto;
    border:2px solid #00f600;
    border-radius: 20px;
    padding: 10px 10px;
    text-align: center;  
    height: 470px;
  }
  .my-account, .changedata{
    width: 80%;
    margin: auto;
    border:2px solid #00f600;
    border-radius: 20px;
    padding: 10px 10px;
    text-align: center; 
  }
  .nin-verification{
    margin-top: 50px;
    width: 80%;
    margin: auto;
    border:2px solid #00f600;
    border-radius: 20px;
    padding: 10px 10px;
    text-align: center; 
  }
  .nin-container{
    display: flex;
    gap: 50px;
    /* justify-content: space-between; */
    width: 60%;
    margin: auto;
  }
  .chart-tit{
    margin-top: -60px;
  }
  .nin-details{
    /* padding-left: -50px;  */
    text-align: left;
  }
  .nin-details p{
  margin-bottom: -15px;
  font-size: 16px;
  }
  .rgister-lab{
    margin-bottom: 5px;
    margin-top: -70px;
  }
  .signout-cont{
    display: flex;
    width: 60%;
    padding-left: 290px;
    margin: auto;
  }
   .forgotten-password-cotainer, .register-user-container, .successful-account-update,.successful-signout,.successful-nin,.failed-nin-verification, .signout{
    width: 80%;
    margin: auto;
    border:2px solid #00f600;
    border-radius: 20px;
    padding: 10px 10px;
    text-align: center;
  }
  .signout-container{
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
  }
  .signout-container button{
    color: #00f600;
    background-color: transparent;
    padding: 10px 20px;
    border: 1px solid #00f600;
    border-radius: 10px;
    margin-bottom: 30px;
    width: 300px;
  }
  .signin-desktop-container span{
    color: red;
    padding-left: 10px;
  }
  .err-msg{
    color: red;
    margin-top: 0;
    margin-bottom: 0;
    font-style: italic; 
  }
  .reg-err{
    color: red;
    margin-top: 0;
    margin-bottom: 0;
  }
  .signin-desktop-container input {
  width: 300px;
  padding: 10px 20px;
  background-color: transparent;
  border:1px solid #00f600;
  border-radius: 10px;
  /* outline: 1px solid white; */
  color: white;
  margin-bottom: -8px;
  }
  .forgotten-password-cotainer input, .shoppingchartContainer input{
    width: 300px;
    padding: 10px 20px;
    background-color: transparent;
    border:2px solid #00f600;
    border-radius: 10px;
    /* outline: 1px solid white; */
    color: white;
    margin-bottom: 80px;
  }
  .shoppingchartContainer div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .shoppingchartContainer input{
    width: 300px;
    padding: 10px 20px;
    background-color: transparent;
    border:2px solid #00f600;
    border-radius: 10px;
    color: white; 
    margin-bottom: 5px;
  }
  .shoppingchartContainer label{
    color: #00f600;
    font-weight: 700;
    font-size: 14px;
    margin-bottom: 4px;
  }
  .shoppingchartContainer h4{
    color: #00f600;
  }
  .register-container{
    /* width: 50%; */
    margin:auto;
  }
  #your-data{
  background-color: black;
  border-color: #00f600;
    color: white;
  }
  #your-nin{
    color: white;
  }
  .nin-field{
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .nin-field label{
    color: #00f600;
  }
  .nin-field input{
    width: 270px;
    padding: 10px 20px;
    background-color: transparent;
    border-color: #00f600;
    color: white;
    border-radius: 10px;
  }
  .lab-email{
    color: #00f600;
    margin-bottom: 5px;
  }
  .register-sec-1 input::-webkit-input-placeholder, .shopping-nin-container input::-webkit-input-placeholder{
    color: #00f600;
  }
  .change-password-contianer input::-webkit-input-placeholder{
    color: #00f600;
  }
  .signin-desktop-container input::-webkit-input-placeholder{
    /* color: white; */
    color: #00f600;
  }
  .forgotten-password-cotainer input::-webkit-input-placeholder,.nin-field input::-webkit-input-placeholder{
    color: #00f600;
  }
  .change-password-contianer input:focus{
    color: white;
  }
  .register-sec-1 input:focus{
    color: white;
  }
  .signin-desktop-container input:focus, .forgotten-password-cotainer input:focus, .shopping-nin-container input:focus{
    color: white;
   }
   .change-password-contianer input:not(:placeholder-shown), .nin-field input:not(:placeholder-shown), .shopping-nin-container input:not(:placeholder-shown)  {
    border-color: white;
   }
   .register-sec-1 input:not(:placeholder-shown){
    border-color: white;
   }
   .signin-desktop-container  input:not(:placeholder-shown), .forgotten-password-cotainer input:not(:placeholder-shown) {
     border-color: white;
   }
  .signin-desktop-container h5{
    font-size: 16px;
  }
  .pls-clk{
    color: #00f600;
    font-size: 14px;
  }
  .pls-clk:hover{
    text-decoration: underline;
    cursor: pointer;
  }
  .go-purchase{
    margin-top: -30px;
    font-size:115px;
    margin-bottom: 5px;
    color: green;
    cursor: pointer;
  }
  .adjuster{
    margin-bottom: 80px;
  }
  .new-Area{
    display: flex;
    gap: 50px;
    font-size: 22px;
    align-items: first baseline;
    border:1px solid #00f600;
    padding: 10px;
    height: 190px;
    border-radius: 20px;
  }
  .left-download-ticket-container{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
  }
  .left-download-ticket-container button{
    background-color: #00f600;
    color: black;
    border-radius: 10px ;
    border: 2px solid white;
    width: 100px;
    font-size: 16px;
    font-weight: 400;
    gap: 20px;
    margin-right: 20px;
    padding: 5px;
  }
  .purchase-location{
    width: 200px;
  }
  #qrCode{
  width: 80%;
  height: 290px;
  }
  #qrCode svg{
    height: 100%;
    width: 100%;
  }
  .area-right{
    display: flex;
    gap: 30px;
    font-size: 14px;
  }
  .purchase-locaion-right{
    width: 220px;
    font-size: 14px;
  }
  .go-purchase-active{
    margin-top: -30px;
    font-size:115px;
    margin-bottom: 5px;
    color:#00f600;
    cursor: pointer;
  }
  
  .go-reg{
    margin-top: -45px;
    font-size:100px;
    margin-bottom: 5px;
    color: green;
  }
  .go-reg-active{
    margin-top: -45px;
    font-size:100px;
    margin-bottom: 5px;
    color: #00f600;
  }
  #fill-data{
    color: white;
  }
  .pls-clk-go{
    font-size: 15px;
    text-decoration: underline;
    color:green;
  }
  .register-sec-1{
    display: flex;
    margin-left: 30px;
    align-items: center;
  }
  .register-sec-1 div{
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .register-sec-1 input{
    background-color: transparent;
    border-color: #00f600;
    color: white;
  }
  .title{
    width: 40px;
    padding: 10px 0px 10px 20px;
    border-radius: 10px;
    margin-right: 40px;
  }
  #title-userData{
    width: 40px;
    padding: 10px 0px 10px 20px;
    border-radius: 10px;
    margin-right: 40px;
    color: gray;
    border:1px solid gray;
  }
  #firstname-userData{
    padding: 10px 20px;
    border-radius: 10px;
    margin-right: 30px;
    border:1px solid gray;
    color: gray;
  }
  .changeData-container{
    display: flex;
    width: 80%;
    margin: auto;
    
  }
  .reg-pwd{
    width: 250px;
    padding: 10px 20px;
    margin-right: 30px;
    border-radius: 10px;
  }
  .firstname-input{
    padding: 10px 20px;
    border-radius: 10px;
    margin-right: 30px;
  }
  .state{
    border-radius: 10px;
    border-color: #00f600;
    width: 100px;
    padding: 10px 20px;
    margin-right: 30px;
  }
  .go-register{
    margin-left: -350px;
    padding-left: 270px;
  }
  .nin-reg{
    margin-top: -70px;
  }
  .register-option{
    margin-left: -200px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .my-account-option{
    padding-left: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: black;
  }
  .my-account-option button{
    color: black;
    background-color: #00f600;
    font-weight: 600;
    padding: 5px 10px;
    width: 110px;
    border-radius: 10px;
    border-color: white;
    margin-bottom: 10px;
  }
  #change-password{
    color: white;
  }
  .register-option button{
    color: black;
    background-color: #00f600;
    padding: 5px 10px;
    width: 110px;
    border-radius: 10px;
    border-color: white;
    margin-bottom: 10px;
  }
  #delete-acount{
    background-color: transparent;
    border-color: red;
    color: red;
    margin-bottom: 50px;
  }
  #sign-out{
    padding: 12px 0px;
  }
  #sign-out-sign-out{
    padding: 12px 0px;
    color: white; 
  }
  .telphone{
    border-radius: 10px;
    border-color: #00f600;
    width: 170px;
    padding: 10px 20px;
    margin-right: 30px;
    /* margin-left: 20px; */
  }
  .title-label{
    margin-left: -40px;
    color: #00f600;
  }
  .title-label-userData{
    margin-left: -40px;
    color: gray;
  }
  .cont-1, .cont-2{
    width: 50%;
    height: 700px;
  }
  .cont-1{
    text-align: center;
    color: white;
  }
  .cont-1-sec{
    width: 100%;
    margin: auto;
    margin-left: 150px;
  }
  .cont-1-sec h2{
    font-size: 40px;
    font-weight: 500;
  }
  .cont-1-sec h3{
    margin-top: -19px;
    font-size: 100px;
    font-weight: 500;
    color: #00f600;
    margin-bottom: -38px;
  }
  /* .failed-nin-verification{
    position: absolute;
    top: 21%;
    background-color: rgba(0, 0, 0, 0.848);
    width: 80%;
    height: 565px;
  } */
  .successful-password{
    position: absolute;
    top: 21%;
    background-color: rgba(0, 0, 0, 0.848);
    width: 80%;
    height: 480px;
  }
  .failed-container{
    padding-top: 55px;
    align-items: center;
    width: 65%;
    margin: auto;
    display: flex;
    gap: 120px;
  }
  .successful-nin-container{
    align-items: center;
    width: 75%;
    margin: auto;
    display: flex;
    gap: 60px;
    padding-top: -20px;
  }
  .successful-signout-user{
    display: flex;
    width: 50%;
    gap: 60px;
    margin: auto;
  }
  .successful-signout-user h3{
    margin-bottom: 60px;
  }
  .successful-signout-user p{
    color: #00f600;
  }
  .successful-nin-container h3{
    font-size: 29px;
    font-weight: 100;
  }
  .successful-nin-container p{
    color: #00f600;
  }
  .failed-sign{
    color: red;
    font-size: 90px;
    margin-top: 140px;
    font-weight: 400;
  }
  .successful-sign, .successful-sign-pwd{
    color: #00f600;
    font-size: 120px;
    margin-top: 140px;
    font-weight: 900;
  }
  .successful-sign-out{
    color: #00f600;
    font-size: 120px;
    margin-top: 70px;
    font-weight: 900; 
  }
  .please-check{
    color: #00f600;
    font-weight: 100;
    font-size: 70px;
    margin-bottom: -25px;
  }
  
  .failed-container h3{
    color: red;
    font-size:25px;
    font-weight: 100;
  }
  .failed-container p{
    color:#00f600;
  }
  .nrc{
    color: #00f600;
    font-size: 15px;
    margin-left: 2px;
  }
  .welcome{
    /* margin-top: -10px; */
    color: #00f600;
    font-weight: 100;
    font-size: 55px;
    margin-bottom: -10px;
  }
  .content{
    width: 49%;
    margin: auto;
    border: 10px solid black;
    background-color: #1f2e3d;
    height: 64%;
    border-radius: 20px;
    text-align: center;
    color: white;
    padding-top: 60px;
  }
  .booking-home-dkt{
    margin-top: -65px;
    display: flex;
    gap: 5px;
    justify-content: center;
    color: #82868a;
    font-size: 17px;
  }
  .booking-container{
   display: flex;
   flex-direction: column;
   padding: 5px 20px;
    width: 80%;
    margin: auto;
    border:2px solid #00f600;
    border-radius: 20px;
    height: 300px;
    text-align: center;
    background-image: linear-gradient(black, rgba(255, 255, 255, 0.091));
    margin-top: -8px;
    /* margin-bottom: 10px; */
  }
  .booking-container p{
   margin-top: -20px;
  }
  .booking-dkt{
    color: white;
  }
  .choose-time{
    /* width: 250px; */
    padding: 10px 35px;
    font-size: 18px;
    background-color: #00f600;
    color: black;
    border-radius: 10px;
    cursor: pointer;
    margin-bottom: 8px;
  }
  .choose-time:hover{
    background-color: #04d604;
  }
  .choose-time-2{
    padding: 10px 35px;
    font-size: 18px;
    background-color: #00f600;
    color: white;
    border-radius: 10px;
  }
  .choose-time-title{
    display: flex;
    flex-direction: row;
    gap: 17px;
    color: #00f600;
  
    padding-left: 10px;
    font-size: 11px;
    font-weight: 700;
  }
  .pick-tim{
    display:"flex";
    align-items:center;
  }
  .select-passenger{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 10px ;
    /* color:; */
    background-color: rgb(16, 67, 16);
    color: black;
    border-radius: 10px;
  }
  .select-passenger p{
    margin-top: 3px;
    margin-bottom: 8px;
    font-size: 19px;
    font-weight: 600;
  }
  .select-passenger-active{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 10px;
    background-color: #00f600;
    color: white;
    border-radius: 10px;
    border: 1px solid white;
  }
  .select-passenger-active p{
    margin-top: 3px;
    margin-bottom: 8px;
    font-size: 19px;
    font-weight: 600;
  }
  .flex{
    display: flex;
  }
  .add-to-chart{
    /* margin: 2px -10px; */
    padding: 0px 10px 6px 10px;
    margin-right: 20px;
    font-size: 25px;
    border:2px solid #00f600;
    border-radius: 60%;
    color: #00f600;
  }
  .travel-time{
    align-items: center;
    gap: 13px;
    font-size: 12px;
    /* padding-left: 10px; */
    margin-bottom: -35px;
  }
  .travel-time-cont{
    border:2px solid #00f600;
    padding: 5px;
    gap: 15px;
    align-items: center;
    border-radius: 10px;
    margin-bottom: 10px;
  }
  .travel-time-cont button:nth-child(1){
    background-color: #76d6fa;
    font-size: 12px;
    height: 40px;
    padding: 0px 10px;
    cursor: pointer;
    border: none;
    /* margin-right: 5px; */
    border-radius: 5px;
    margin-left: -10px;
  }
  
  .travel-time-cont button:nth-child(2){
    background-color: #eb58ed;
    height: 40px;
    padding: 0px 5px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
  }
  .travel-arrow p{
    padding-top: 10px;
    color: #00f600;
    /* font-size: 178px; */
    font-weight: 10;
    margin-bottom: -5px;
    /* width: 150px; */
  }
  .hide{
    display: none;
  }
  .tim-class{
    color: #00f600;
    font-weight: 750;
    margin-top: -10px;
    margin-bottom: 4px;
    font-size:20px;
  }
  .tim-class-white{
    color: white;
    font-weight: 750;
    margin-top: -10px;
    margin-bottom: 4px;
    font-size:20px; 
  }
  .tim-class-1{
    color: #00f600;
    font-weight:700;
   margin-top:10px;
    font-size:20px;
    /* font-weight: 900; */
    margin-bottom: 4px;
  }
  .tim-class-3{
    color: rgb(16, 67, 16);
    font-weight:700;
   margin-top:-3px;
    font-size:20px;
    /* font-weight: 750; */
    margin-bottom: 4px; 
  }
  .choose-time-container{
    /* padding-top: -120px; */
    padding: 0px 10px;
    background-color: black;
    position: absolute;
    width: 288px;
    margin: auto;
    margin-top: 182px;
    margin-left: -12px;
    z-index: 10000;
    height: 120px;
   
    border: 2px solid white;
    border-radius: 20px;
  }
  .container-list-trv{
    overflow-y: scroll;
    height: 80px;
  }
  .container-list-trv::-webkit-scrollbar{
    width: 5px;
   
  }
  .container-list-trv::-webkit-scrollbar-track{
    color:white ;
  }
  .container-list-trv::-webkit-scrollbar-thumb{
    background-color: rgb(118, 117, 117);
    border-radius: 5px;
    height: 30px;
  }
  .choose-time-container p{
    margin-top: 10px;
  }
  .select-trip{
    margin-top: -40px;
    font-size: 22px;
    margin-bottom: -20px;
    /* -ms-text-kashida-space: 50px; */
  }
  .From{
    background-color: #00f600;
    appearance: none;
    text-align: center;
    cursor: pointer;
    color: black;
    /* user-select: none; */
    font-size: 45px;
    text-transform:uppercase;
    font-weight: 650;
    /* font-family: Montserrat; */
    padding: 4px 10px;
    /* padding: 25px; */
    margin-bottom: 20px;
    border: 1px solid white;
    border-radius: 10px;
    /* color: greenyellow; */
    
  }
  
  
  .dropdownContent{
    position: absolute;
    background-color: black;
    color: #00f600;
    width: 244px;
    margin-left: -9px;
    border-radius: 10px;
    padding: 10px;
    font-size: 19px;
    height: 180px;
    overflow-y: scroll;
    scroll-margin-right: -10px;
    scrollbar-width:thin;
    scrollbar-track-color:transparent;
    scrollbar-base-color: black;
    scroll-snap-type: none;
  }
  .dropdownContent::-webkit-scrollbar{
    background-color: black;
    scroll-margin-right: -10px;
    scrollbar-width:thin;
    scrollbar-track-color:transparent;
    scrollbar-base-color: black;
    scroll-snap-type: none;
    color: black;
  }
  .type-sec{
    font-size:20px;
     font-weight:700;
     margin-bottom: 15px;
     margin-top:5px;
  }
  .setting-policy{
    font-size:18px;
    margin-top:-85px;
    margin-bottom: 30px;
  }
  
  .dropdownItem{
    /* padding: 10px; */
    padding-bottom: 10px;
    cursor: pointer;
    /* transition: all 0.2s; */
  }
  .dropdownItem:hover{
  border:1px solid #00f600;
  border-radius: 10px;
  /* padding: 10px; */
  }
  .From select option:focus-visible{
  
  background-color: green;
  border: 1px solid #00f600;
  }
  select option{
    font-size: 14px;
  }
  
  .selection{
    display: flex;
    flex-direction: column;
    width: 75%;
    margin: auto;
    margin-top: 40px;
  }
  
  .date{
    appearance: none;
    text-align: center;
    text-transform: uppercase;
    font-weight: 650;
    font-size: 40px;
    cursor: pointer;
    color: black;
    background-color: #00f600;
    border: 1px solid white;
    padding: 10px;
    /* margin-bottom: 20px; */
    border-radius: 10px;
  }
  
  .date-update{
    appearance: none;
    text-align: center;
    text-transform: uppercase;
    font-weight: 530;
    font-size: 30px;
    color: white;
    background-color:black;
    border: 1px solid #00f600;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 10px; 
  }
  input[type="date"]{
    background-color: #00f600;
    font-weight: 400;
    /* -webkit-calendar-picker-indicator:dis */
  }
  input[type="date"]::before{
   background-color: black;
   color: white;
    /* -webkit-calendar-picker-indicator:dis */
  }
  input[type="date"]::-webkit-calendar-picker-indicator {
    /* display: none; */
    width: 15px;
    color: white;}
    
  input[type="date"]:focus{
    background-color: #00f600;
    color: white;
    /* -webkit-calendar-picker-indicator:dis */
  }
  
  /* input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    background-color: greenyellow;
  } */
  .date::placeholder{
    color: black;
    font-weight: 550;
  }
  /* input[type="date"]::-webkit-datetime-edit-text{
    display: none;
    -webkit-ap
  } */
  .payment_procedure{
    width: 80%;
    margin: auto;
    margin-top: 40px;
   
    border:2px solid #00f600;
    border-radius: 20px;
    height: 600px;
    padding: 20px;
  }
  .payment_procedure form{
    width: 60%;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
  .payment_procedure form div{
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .payment_procedure input{
    background-color: transparent;
    border:2px solid #00f600;
    padding: 20px;
    border-radius: 15px;
    outline: none;
    color: white;
  }
  .payoutGoBtn{
    padding-top: 40px;
  }
  #payment-submit-btn{
    margin-top: 40px;
    font-size: 20px;
    width:140px;
  }
  .sect-go-text{
    color: rgb(2, 84, 2);
    margin-top: -5px;
    margin-bottom:-70px ;
  }
   .selGO-bk2{
    color: rgb(2, 84, 2);
    margin-top: 5px;
    margin-bottom:-30px ;
   }
  .sect-go{
    color: rgb(3, 33, 3);
    margin-top: 40px;
    /* padding-top: -160px; */
    /* margin-top: -42px; */
    font-size: 10px;
    font-weight: bolder;
    /* font-family: Montserrat; */
    cursor: pointer;
    /* overflow: hidden; */
  }
  .sect-go-booking{
    color: rgb(3, 33, 3);
    /* margin-top: 10px; */
    /* padding-top: -160px; */
    /* margin-top: -42px; */
    font-size: 10px;
    font-weight: bolder;
    /* font-family: Montserrat; */
    cursor: pointer;
    /* overflow: hidden; */
  }
  .sect-go1{
    margin-top: -40px;
    color: rgba(7, 69, 5, 0.597);
    font-size: 100px;
    margin-top: -40px;
    cursor: pointer;
  }
  .sect-goCart{
    margin-top: -30px;
    font-size: 150px;
    font-weight: 900;
    cursor: pointer; 
    color:  rgb(16, 67, 16);
  }
  .sect-go2{
    color: #00f600;
    margin-top: -40px;
    margin-top: -40px;
    font-size: 100px;
    cursor: pointer;
  }
  .sect-goactive{
    margin-top: -52px;
    font-size: 150px;
    font-weight: 900;
    cursor: pointer; 
  
    color: #00f600;
  }
  .sect-goactive-buy{
    margin-top: -30px;
    font-size: 150px;
    font-weight: 900;
    cursor: pointer; 
  
    color: #00f600;
  }
  
  #go{
    margin-top: -42px;
    font-size: 150px;
  }
  #home-go{
    margin-top: -42px;
    font-size: 140px;
    cursor: pointer;
  }
  #bookingGO{
    margin-top: -60px;
    /* padding-top: -50px; */
    font-size: 150px;
    font-weight: 900;
    cursor: pointer; 
    color:  rgb(16, 67, 16);
  }
  #goBooking{
    margin-top: -10px;
    font-size: 120px;
    font-weight: 900;
    cursor: pointer; 
    color:  rgb(16, 67, 16);
  }
  #goBookingActive{
    margin-top: -10px;
    font-size: 120px;
    font-weight: 900;
    cursor: pointer; 
    color: #00f600;  
  }
  #bookingGO1{
    margin-top: -52px;
    font-size: 150px;
    font-weight: 900;
    cursor: pointer; 
    color: #00f600; 
  }
  footer{
    position: absolute;
    width: 100%;
    font-size: 20px;
    /* line */
    color: white;
    bottom: 0;
    margin-bottom: 20px;
    text-align: center;
  }
  .wait-container{
    width: 55%;
    margin:auto;
    height: 650px;
    border: 2px solid #00f600;
    border-radius: 20px;
    background-color: black;
  }
  .please-wait{
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 48px;
    font-weight: 100;
  }
  .please-wait span{
    color: #00f600;
    margin-left: 5px;
  }
  .processing-order{
    width: 55%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #00f600;
    font-size: 26px;
    border-radius: 50%;
    border: 2px solid #00f600;
    height: 400px;
  }
  /* mobile */
  .mobileNav{
    display: flex;
    justify-content: space-between;
    background-color: transparent;
    align-items: flex-end;
  }
  .hamburger{
    color: #00f600;
    font-size: 40px;
  }
  .headername{
    color: #00f600;
    font-size: 40px;
  }
  .shopping-cart-mb{
    position: absolute;
    top: 0;
    left: 0;
    width: 80%;
    border-radius: 10px;
    background-image: linear-gradient(black,rgb(83, 81, 81));
    color: white;
    border:2px solid #00f600; 
    padding: 5px 20px;
    margin-top: 10px;
  }
  .shopping-cart-mb div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
  }
  .shopping-cart-mb h4, .information-mb h4, .sign-in-mb h4, .purchase-mb h4{
    color: #00f600;
  }
  .shopping-cart-mb h3, .information-mb h3, .sign-in-mb h3, .purchase-mb h3{
    cursor: pointer;
    color: #82868a;
  }
  .information-mb{
    position: absolute;
    top: 0;
    left: 0;
    width: 80%;
    border-radius: 10px;
    background-image: linear-gradient(black,rgb(83, 81, 81));
    color: white;
    border:2px solid #00f600; 
    padding: 5px 20px;
    margin-top: 10px; 
  }
  .information-mb div, .sign-in-mb div, .purchase-mb div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
  }
  .purchase-mb{
    position: absolute;
    top: 0;
    left: 0;
    width: 80%;
    border-radius: 10px;
    background-image: linear-gradient(black,rgb(83, 81, 81));
    color: white;
    border:2px solid #00f600; 
    padding: 5px 20px;
    margin-top: 10px; 
    padding-bottom: 20px; 
  }
  .sign-in-mb{
    position: absolute;
    top: 0;
    left: 0;
    width: 80%;
    border-radius: 10px;
    background-image: linear-gradient(black,rgb(83, 81, 81));
    color: white;
    border:2px solid #00f600; 
    padding: 5px 20px;
    margin-top: 10px; 
    padding-bottom: 20px;
  }
  .sign-in-mb-form{
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
  }
  .sign-in-mb-form div{
    display: block;
  }
  .sign-in-mb-form label{
    font-size: 11px;
    font-weight: 700;
    color: #00f600;
  }
  .pls-sign-in-mb{
    margin-top: -10px;
  }
  .sign-in-mb-form input{
    width: 85%;
    padding: 10px 20px;
    border-radius: 7px;
    background-color: #d8dbdd;
  }
  .register-mb{
    width: 100%;
    text-align: center;
    color: black;
    background-color: #00f600;
    padding: 10px;
    font-weight: 500;
    font-size: 18px;
    border-radius: 8px;
  }
  .sign-in-mb-form button{
    margin-top: 10px;
    color: black;
    background-color: #00f600;
    width: 40%;
    padding: 10px;
    border-radius: 8px;
  }
  .active{
    color: #00f600;
  }
  .please-go-desk{
    color: green;
    text-align: center;
  }
  .select-trip-go-container{
    display: flex;
    width: 90%;
    margin: auto;
    gap: 10px;
  }
  .select-trip-go-container-activ{
    display: flex;
    width: 90%;
    margin: auto;
    margin-top: 10px;
    gap: 10px;
  }
  .to-proceed{
    font-size:12px;
    margin-top:5px;
     margin-bottom :-10px;
  }
  .miniGO{
    font-size:11px;
    margin-top:5px;
     margin-bottom:-10px;
      color:#00f600

  }
  .go-shopping-chart{
    display: flex;
    justify-content: center;
    gap: 160px;
  }
  .go-payout{
    display: flex;
    justify-content: center;
    gap: 100px;
  }
  .go-shopping-chart button, .select-trip-go-container button, .go-payout button{
    height: 30px;
    padding:  6px 20px 4px 23px;
    background-color: transparent;
    color: rgb(16, 67, 16);
    margin-top: 55px;
    border-radius: 20px;
    border: 2px solid rgb(16, 67, 16);
    /* margin-top: 10px;  */
  }
  #prev-btn-shopping-chart{
    color: #00f600;
    border-color: #00f600;
    /* margin-top: 45px; */
  }
  
  
  .nav-modal{
    background-image: linear-gradient(black,rgb(83, 81, 81));
    color: white;
    border:2px solid #00f600;
    height: 300px;
    width: 220px;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1000;
    padding-left: -10px;
    margin-right: -30px;
    border-radius: 10px;
  }
  .nav-modal ul{
    margin-left: -25px;
    display: flex;
    flex-direction: column;
    list-style-type: none;
    font-size: 18px;
  }
  .nav-modal ul li{
    margin-bottom: 20px;
  }
  .mobileHome{
    background-image: url('./images/background.png');
    width: 100%;
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .headings{
    margin-top: -15px;
    text-align: center;
    color: white;
  }
  .titl-1, .titl-2{
    font-size: 18px;
  }
  .titl-2{
    color: #00f600;
    margin-bottom: -10px;
  }
  .content-mb{
    color: white;
  }
  .content-mb-container {
    margin-top: 40px;
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  }
  .sub-content-mobile{
    width: 90%;
    margin-top: auto;
  }
  .select-trip-mb{
    text-align: center;
    font-size: 18px;
  }
  .form-mb{
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: auto;
  }
  .date-mb{
    appearance: none;
    text-align: center;
    text-transform: uppercase;
    font-weight: 530;
    font-size: 40px;
    color: black;
    background-color: #00f600;
    border: 1px solid white;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
  }
  .sel-mb{
    margin-top: -10px;
  }
  #go-mb{
    margin-top: -42px;
    font-size: 160px;
  }
  .go-options{
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    width: 100%;
    margin:auto;
  }
  .go-options button{
    height: 30px;
    padding:  6px 20px 4px 23px;
    background-color: transparent;
    color: green;
    border-radius: 20px;
    border: 2px solid green;
    margin-top: 20px;
  }
  .mb-footer{
    font-size: 14px;
    margin-bottom: 5px;
  }
  .booking-mb{
    width: 85%;
    margin: auto;
  }
  .booking-mb-title{
    display: flex;
    align-items: center;
    color: #82868a;
    margin-bottom: -10px;
   
  }
  .booking-mb-title p{
    padding-right: 5px;
  }
  .arrow-left{
    font-size: 14px;
    margin-top: 10px;
  }
  .booking-mb-container{
    background-image: linear-gradient(black,rgb(83, 81, 81));
    text-align: center;
    color: white;
   border-radius: 15px;
    width: 100%;
    margin: auto;
    border:2px solid #00f600;
    height: 300px;
  }
  .booking-bm-go{
    text-align: center;
  }
  .mb-trip{
    margin-top: 4px;
    margin-bottom: -10px;
    font-weight: 400;
    font-size: 25px;
  }
  .mb-date{
    margin-top: -15px;
    margin-bottom: -10px;
  }
  .time-class-mb{
    display: flex;
    flex-direction: column;
  }
  .time-class-mb button{
    width: 90%;
    margin: auto;
    padding: 15px;
    font-size: 18px;
    border-radius: 10px;
    background-color: #00f600;
    /* margin-bottom: 20px; */
  }
  .time-class-mb-table{
    display: none;
    background-color: white;
    color: black;
    width: 75%;
    position: absolute;
    margin:auto;
    margin-left: 20px;
    margin-top: 65px;
  }
  .time-class-mb-table th{
    width: 70px;
  }
  .mb-trip-detail{
    width: 30%;
    margin: auto;
    display:flex;
     gap:10px;
      text-align:center;
  }
  .add-passagers-mb{
    display: flex;
    justify-content: space-between;
    color: black;
    width: 75%;
    margin: auto;
    align-items: center;
    background-color: green;
    padding: 5px 20px;
    border-radius: 10px;
  }
  .add-passagers-mb div{
    font-size: 22px;
    font-weight: 700;
  }
  .increase-btn, .decrease-btn{
    padding: 4px 10px;
    border-radius: 50%;
  }
  .increase-btn:hover, .decrease-btn:hover{
    font-size: 20px;
    color: white;
    background-color: #00f600;
  }
  .go-options .prev-btn-mb{
    border:2px solid #00f600;
    color: #00f600;
  }
  .go-options .prev-btn-mb:hover{
    border:2px solid white;
    background-color: #00f600;
    color: black;
  }
  .totalpriceP{
    margin-left:20px;
  }
  .totalprice-buyTicket-p{
    margin-left:20px;
  }
}

@media (max-width:1324px){
    nav{
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
      background-color: black;
    }
    nav ul{
      display: flex;
      list-style-type: none;
    }
    nav ul li{
      padding-right: 30px;
      color: white;
      font-size: 20px;
      font-weight: 600;
      cursor: pointer;
    }
    button{
      cursor: pointer;
    }
    .home{
      background-image: url('./images/backgroundImg.jpg');
      background-position-y:10px ;
      position: fixed;
      width: 100%;
      height: 100vh;
      background-repeat: no-repeat;
      background-size: cover;
    }
    .nrc-name{
      font-weight: 900;
    }
    .home-container{
      width: 100%;
      display: flex;
      height: 100vh;
      margin-top: 60px;
    }
    .cancel-desktop{
      display: flex;
      justify-content: flex-end;
      padding-right: 28px;
    }
    
    .cancel-desktop h2:hover{
      color: gray;
      cursor: pointer;
    }
    .trip-title{
      font-size:20px;
       margin-top:10px;
      font-weight:700;
    }
    .from-to-booking{
      font-size:18px;
      margin-top:-28px;
      margin-bottom:30px;
    }
    .bookingDate{
      font-size:20px;
       margin-top:-20px;
       font-weight:700;
    }
  .booking-newDate{
    font-size:18px;
    margin-top:-35px;
  }
  
    .info{
      color: #00f600;
      text-align: center;
      font-size: 50px;
      font-weight: 400;
    }
    .purchase-info{
      margin-top: -5px;
    }
    .purchase-container{
      display: flex;
      justify-content: space-evenly;
      width: 90%;
      margin: auto;
    }
    .purchase-container h3, .purchase-container h2{
      color: #00f600;
    }
    .left-container-purchase{
      height: 300px;
      overflow-y: scroll;
    }
    .succesfull-login{
      display: flex;
      width: 95%;
      margin: auto;
      border-radius: 20px;
      border:2px solid #00f600;
      background-color: black;
      text-align: center;
      align-items: center;
      gap: 30px;
      height: 300px;
    }
    .wel-desktop{
      border:2px solid #00f600;
      width: 70%;
      font-size: 20px;
      margin: auto;
      padding: 10px 50px;
      border-radius: 20px;
    }
    .successmark{
      color: #00f600;
      font-size: 140px;
      padding: 10px 30px 10px 20px;
      margin-left: 120px;
    }
    .successmark-email{
      color: #00f600;
      font-size: 140px;
      padding: 10px 30px 10px 20px; 
    }
    .change-password-contianer{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .change-password-contianer div{
      display: flex;
      flex-direction: column;
    }
    .change-password-contianer input{
      width: 350px;
      padding: 10px 20px;
      border-radius: 10px;
      border-color: #00f600;
      background-color: transparent;
      margin-bottom: 10px;
    }
    .myaccount-cont{
      width: 80%;
      margin: auto;
      align-items: flex-start;
      display: flex;
      padding-left: 260px;
    }
    .change-password-contianer label{
      color: #00f600;
      font-size: 16px;
      font-weight: 500;
      padding-bottom: 5px;
    }
    .wel-login{
      color: #00f600;
      font-size: 40px;
      font-weight: 100;
      margin-bottom: -5px;
    }
    .username-login{
      margin-top: 0;
      font-size: 25px;
      font-weight: 100;
      margin-bottom: 0;
    }
    .succesfull-login h5{
      color: #00f600;
    }
   
    .desktop-information-modal,  .shoppingChartmodal{
      top: 0;
      color: white;
      /* position: absolute; */
      width: 80%;
      margin: auto;
      margin-top: -60px;
      /* margin-left: 345px; */
      height: 700px;
      background-image: linear-gradient(black,rgba(92, 90, 90, 0.518));
      background-color: black;
      border-radius: 20px;
    }
    .pruchase-desktop-modal{
      top: 0;
      color: white;
      /* position: absolute; */
      width: 80%;
      margin: auto;
      margin-top: 160px;
      /* margin-left: 345px; */
      height: 700px;
      background-image: linear-gradient(black,rgba(92, 90, 90, 0.518));
      background-color: black;
      border-radius: 20px;
    }
    .ticketReciept{
      top: 0;
      left: 0;
      color: white;
      /* position: absolute; */
      padding-top: 190px;
      width: 70%;
      margin: auto;
      /* margin-top: 105px; */
      /* margin-left: 585px;  */
      height: 720px;
      background-color: rgb(7, 7, 7);
      border-radius: 20px;
    }
    .ticketReciept_qr{
      top: 0;
      left: 0;
      color: white;
      position: absolute;
      padding-top: 20px;
      width: 40%;
      margin: auto;
      margin-top: 145px;
      margin-left: 585px; 
      height: 670px;
      background-color: rgb(7, 7, 7);
      border-radius: 20px; 
    }
    .ticket_detials{
      display: flex;
      width: 78%;
      margin: auto;
      align-items: flex-start;
      gap: 30px;
      /* padding-top: -200px; */
    }
    .ticket_detials h2{
      margin-top: -5px;
      font-weight: 500;
      font-size: 18px;
      margin-bottom: -35px;
    }
    .ticket_detials p{
      margin-bottom: -20px;
      font-size: 13px;
    }
    .tick-data{
      width: 88%;
      margin: auto;
      display: flex;
    }
    .tick-data ul {
      display: flex;
      flex-direction: column;
      gap: 14px;
      margin-top: 30px;
    }
    .tick-footer{
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      color: #00f600;margin-top: 20px;
    }
    .ticket-modal{
      margin-top: -120px;
      width: 50%;
      margin: auto;
      height: 692px; 
      border:2px solid #00f600;
      border-radius: 20px;
      background-image: linear-gradient(black,rgba(92, 90, 90, 0.518));
    }
    .your-ticket{
      display: flex;
      align-items: center;
      gap: 100px;
      color: #00f600;
    }
    .click-Qr-text{
      position: absolute;
      top: 0;
      /* left: 0; */
      transform: rotate(-90deg);
      font-weight: 400;
      /* width: 50%; */
      font-size: 24px;
      color: #00f600;
      margin-top: 225px;
      /* margin-right: -300px; */
      margin-left: -125px;
    }
    .click-Qr-text-2{
      position: absolute;
      /* top: 0; */
      /* right: 0; */
      transform: rotate(-90deg);
      font-weight: 400;
      /* width: 460px; */
      font-size: 24px;
      color: #00f600;
      margin-top: -160px;
      margin-left: -175px;
      /* padding-right: 169px;  */
    }
    .your-ticket-container{
      display: flex;
      justify-content: flex-end;
      padding:0px 25px;
    }
    .your-ticket h2{
      font-weight: 500;
    }
    .your-ticket p{
      font-size: 22px;
    }
    .cancelTicket{
      display: flex;
      justify-content: flex-end;
      font-size: 30px;
      margin-top: -10px;
      margin-right: 20px;
    }
    .qr-container{
      display: flex;
      justify-content: center;
      align-items: flex-end;
    }
    /* .qr-container div{
    width: 280px;
    height: 220px;
    overflow: auto;
    } */
    .ticket-sub-title ul li{
    font-size: 15px;
    margin-top: -15px;
    }
    .container-for-modals{
      /* margin-top: 700px; */
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      /* height: 100vh; */
      margin-top: 50%;
    }
    .shopping-main{
      top: 0;
      color: white;
     
      width: 90%;
      margin: auto;
      margin-top: 140px;
      /* margin-left: 345px; */
      height: 720px;
      background-image: linear-gradient(black,rgba(92, 90, 90, 0.518));
      background-color: black;
      border-radius: 20px; 
    }
    .shopping-title{
      margin-bottom: -18px;
    }
    .shopping-top{
      display: flex;
      align-items: center;
      text-align: center;
      margin-top: 0;
      gap: 5px;
      font-size: 19px;
      padding-inline-start: 20px;
      justify-content: center;
    }
    .shopping-top-ticket{
      display: flex;
      align-items: center;
      text-align: center;
      margin-top: 25px;
      gap: 5px;
      font-size: 19px;
      padding-inline-start: 20px;
      justify-content: center; 
    }
    .shopping-top p{
      color: #82868a;
    }
    .curr-bookin{
      display: flex;
      justify-content: space-between;
      margin-top: -10px;
    }
    .curr-bookin h2{
      color: #00f600;
      font-weight: 500;
      font-size: 28px;
    
      margin-bottom: -60px;
      /* margin-top: -20px; */
      padding-left: 30px;
    }
    .qty-booking{
      padding-left: 30px;
      display: flex;
      gap: 21px; 
      margin-top: -24px;
      margin-bottom: -30px;
    }
    .qty-booking h2{
      margin-top: -10px;
      color: #00f600;
      font-size: 24px;
      font-weight: 500;
    }
    .qty-booking-list{
      display: flex;
      flex-direction: column;
      gap: 30px;
      width: 92%;
      margin: auto;
      height: 240px;
      overflow-y: scroll;
    }
    .qty-booking-list::-webkit-scrollbar{
      background-color: transparent;
      width: 10px;
    }
    .qty-booking-list::-webkit-scrollbar-thumb{
      border-radius: 10px;
      /* color: #82868a; */
      background-color: rgb(118, 117, 117);
      /* border-radius: 5px; */
    }
    .qty-booking-list::-webkit-scrollbar-track{
      background-color: transparent;
      color: black;
    }
    .qty-booking-list-content{
      align-items: first baseline;
      margin-bottom: -45px;
      gap: 30px;
    }
    .totalprice{
      /* margin-top: -40px; */
      /* margin-top: 10px; */
      font-size: 25px;
      font-weight: 400;
      
      width: 68%;
      margin: auto;
    }
    .totalprice-buyTicket{
      font-size: 22px;
      font-weight: 400;
      width: 68%;
      margin: auto;
      margin-top: 30px;
    }
    .totalprice-buyTicket span{
      font-size: 22px;
    }
    .cont-purchase{
      width: 68%;
      margin: auto;
      text-align: center;
    }
    .cont-purchase p {
      color: #00f600;
      font-size: 25px;
      /* margin-left: 70px; */
      font-weight: 400;
      margin-bottom: 30px;
      margin-top: -56px;
    }
    .cont-purchase button{
      font-size: 20px;
      margin-top: -10px;
      margin-bottom: 15px;
    }
    .withoutreg-style{
      color: black;
      background-color: #00f600;
      width:250px ;
      font-weight: 700;
      font-size: 20px;
      padding: 15px 20px;
      border-radius: 15px;
      border: 1px solid white;
    }
    .withoutreg-style1{
      color: white;
      background-color: transparent;
      width:250px ;
      font-weight: 700;
      font-size: 20px;
      padding: 15px 20px;
      border-radius: 15px;
      border: 1px solid #00f600;
    }
    .cont-purchase button:nth-child(1){
      margin-right: 20px;
    }
    .cont-purchase-verify p{
      margin-top: -56px;
      padding-left: 40px;
    }
    .cont-purchase-verify-btnActive{
      color: white;
      background-color: #00f600;
      width:220px ;
      font-weight: 700;
      font-size: 18px;
      padding: 15px 20px;
      border-radius: 15px;
      border: 1px solid white;
    }
    .cont-purchase-verify-btn{
      color: black;
      background-color:  #00f600;
      width:180px ;
      font-weight: 700;
      font-size: 18px;
      padding: 15px 20px;
      border-radius: 15px;
      border: 1px solid white;
    }
    .purchase-sucessfull{
      text-align: center;
    }
    .purchase-mark{
    font-size: 110px;
    color: #00f600;
    font-weight: 1200;
    margin-top: 90px;
    margin-bottom: -20px;
    }
    .thanks-for-purchase{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 50px;
      padding: 0;
      margin: 0;
    }
    .thanks-for-purchase span{
      display: flex;
      gap: 10px;
      align-items: center;
      color: #00f600;
      margin-right: 5px;
    
      margin-bottom: -110px;
     
    }
    .thanks-for-purchase span p{
      color: white;
    }
    .forwarded{
      color: #00f600;
    }
    .shoppingchartContainer{
      width: 50%;
      margin: auto;
      border: 2px solid #00f600;
      height: 380px;
      text-align: center;
      border-radius: 20px;
    }
    
    .chart-container{
      width: 90%;
      margin: auto;
      border: 2px solid #00f600;
      height: 300px;
      border-radius: 20px;
      margin-bottom: -20px;
      overflow: hidden;
    }
    .signin-desktop{
      top: 0;
      color: white;
      position: absolute;
      width: 70%;
      margin: auto;
      margin-top: -210px;
      height: 680px;
      background-image: linear-gradient(black,rgba(92, 90, 90, 0.518));
      background-color: black;
      border-radius: 20px;
    }
    .signin-data{
      margin-top: -65px;
    }
    .reg-email{
      margin-top: 20px;
    }
    .signin-desktop-container{
      width: 80%;
      margin: auto;
      border:2px solid #00f600;
      border-radius: 20px;
      padding: 10px 10px;
      text-align: center;  
      height: 470px;
    }
    .my-account, .changedata{
      width: 80%;
      margin: auto;
      border:2px solid #00f600;
      border-radius: 20px;
      padding: 10px 10px;
      text-align: center; 
    }
    .nin-verification{
      margin-top: 50px;
      width: 80%;
      margin: auto;
      border:2px solid #00f600;
      border-radius: 20px;
      padding: 10px 10px;
      text-align: center; 
    }
    .nin-container{
      display: flex;
      gap: 50px;
      /* justify-content: space-between; */
      width: 60%;
      margin: auto;
    }
    .chart-tit{
      margin-top: -60px;
    }
    .nin-details{
      /* padding-left: -50px;  */
      text-align: left;
    }
    .nin-details p{
    margin-bottom: -15px;
    font-size: 16px;
    }
    .rgister-lab{
      margin-bottom: 5px;
      margin-top: -70px;
    }
    .signout-cont{
      display: flex;
      width: 60%;
      padding-left: 290px;
      margin: auto;
    }
     .forgotten-password-cotainer, .register-user-container, .successful-account-update,.successful-signout,.successful-nin,.failed-nin-verification, .signout{
      width: 80%;
      margin: auto;
      border:2px solid #00f600;
      border-radius: 20px;
      padding: 10px 10px;
      text-align: center;
    }
    .signout-container{
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
    }
    .signout-container button{
      color: #00f600;
      background-color: transparent;
      padding: 10px 20px;
      border: 1px solid #00f600;
      border-radius: 10px;
      margin-bottom: 30px;
      width: 300px;
    }
    .signin-desktop-container span{
      color: red;
      padding-left: 10px;
    }
    .err-msg{
      color: red;
      margin-top: 0;
      margin-bottom: 0;
      font-style: italic; 
    }
    .reg-err{
      color: red;
      margin-top: 0;
      margin-bottom: 0;
    }
    .signin-desktop-container input {
    width: 300px;
    padding: 10px 20px;
    background-color: transparent;
    border:1px solid #00f600;
    border-radius: 10px;
    /* outline: 1px solid white; */
    color: white;
    margin-bottom: -8px;
    }
    .forgotten-password-cotainer input, .shoppingchartContainer input{
      width: 300px;
      padding: 10px 20px;
      background-color: transparent;
      border:2px solid #00f600;
      border-radius: 10px;
      /* outline: 1px solid white; */
      color: white;
      margin-bottom: 80px;
    }
    .shoppingchartContainer div{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .shoppingchartContainer input{
      width: 300px;
      padding: 10px 20px;
      background-color: transparent;
      border:2px solid #00f600;
      border-radius: 10px;
      color: white; 
      margin-bottom: 5px;
    }
    .shoppingchartContainer label{
      color: #00f600;
      font-weight: 700;
      font-size: 14px;
      margin-bottom: 4px;
    }
    .shoppingchartContainer h4{
      color: #00f600;
    }
    .register-container{
      /* width: 50%; */
      margin:auto;
    }
    #your-data{
    background-color: black;
    border-color: #00f600;
      color: white;
    }
    #your-nin{
      color: white;
    }
    .nin-field{
      margin-top: 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .nin-field label{
      color: #00f600;
    }
    .nin-field input{
      width: 270px;
      padding: 10px 20px;
      background-color: transparent;
      border-color: #00f600;
      color: white;
      border-radius: 10px;
    }
    .lab-email{
      color: #00f600;
      margin-bottom: 5px;
    }
    .register-sec-1 input::-webkit-input-placeholder, .shopping-nin-container input::-webkit-input-placeholder{
      color: #00f600;
    }
    .change-password-contianer input::-webkit-input-placeholder{
      color: #00f600;
    }
    .signin-desktop-container input::-webkit-input-placeholder{
      /* color: white; */
      color: #00f600;
    }
    .forgotten-password-cotainer input::-webkit-input-placeholder,.nin-field input::-webkit-input-placeholder{
      color: #00f600;
    }
    .change-password-contianer input:focus{
      color: white;
    }
    .register-sec-1 input:focus{
      color: white;
    }
    .signin-desktop-container input:focus, .forgotten-password-cotainer input:focus, .shopping-nin-container input:focus{
      color: white;
     }
     .change-password-contianer input:not(:placeholder-shown), .nin-field input:not(:placeholder-shown), .shopping-nin-container input:not(:placeholder-shown)  {
      border-color: white;
     }
     .register-sec-1 input:not(:placeholder-shown){
      border-color: white;
     }
     .signin-desktop-container  input:not(:placeholder-shown), .forgotten-password-cotainer input:not(:placeholder-shown) {
       border-color: white;
     }
    .signin-desktop-container h5{
      font-size: 16px;
    }
    .pls-clk{
      color: #00f600;
      font-size: 14px;
    }
    .pls-clk:hover{
      text-decoration: underline;
      cursor: pointer;
    }
    .go-purchase{
      margin-top: -30px;
      font-size:115px;
      margin-bottom: 5px;
      color: green;
      cursor: pointer;
    }
    .adjuster{
      margin-bottom: 80px;
    }
    .new-Area{
      display: flex;
      gap: 50px;
      font-size: 22px;
      align-items: first baseline;
      border:1px solid #00f600;
      padding: 10px;
      height: 190px;
      border-radius: 20px;
    }
    .left-download-ticket-container{
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px;
    }
    .left-download-ticket-container button{
      background-color: #00f600;
      color: black;
      border-radius: 10px ;
      border: 2px solid white;
      width: 100px;
      font-size: 16px;
      font-weight: 400;
      gap: 20px;
      margin-right: 20px;
      padding: 5px;
    }
    .purchase-location{
      width: 200px;
    }
    #qrCode{
    width: 80%;
    height: 290px;
    }
    #qrCode svg{
      height: 100%;
      width: 100%;
    }
    .area-right{
      display: flex;
      gap: 30px;
      font-size: 14px;
    }
    .purchase-locaion-right{
      width: 220px;
      font-size: 14px;
    }
    .go-purchase-active{
      margin-top: -30px;
      font-size:115px;
      margin-bottom: 5px;
      color:#00f600;
      cursor: pointer;
    }
    
    .go-reg{
      margin-top: -45px;
      font-size:100px;
      margin-bottom: 5px;
      color: green;
    }
    .go-reg-active{
      margin-top: -45px;
      font-size:100px;
      margin-bottom: 5px;
      color: #00f600;
    }
    #fill-data{
      color: white;
    }
    .pls-clk-go{
      font-size: 15px;
      text-decoration: underline;
      color:green;
    }
    .register-sec-1{
      display: flex;
      margin-left: 30px;
      align-items: center;
    }
    .register-sec-1 div{
      display: flex;
      flex-direction: column;
      text-align: center;
    }
    .register-sec-1 input{
      background-color: transparent;
      border-color: #00f600;
      color: white;
    }
    .title{
      width: 40px;
      padding: 10px 0px 10px 20px;
      border-radius: 10px;
      margin-right: 40px;
    }
    #title-userData{
      width: 40px;
      padding: 10px 0px 10px 20px;
      border-radius: 10px;
      margin-right: 40px;
      color: gray;
      border:1px solid gray;
    }
    #firstname-userData{
      padding: 10px 20px;
      border-radius: 10px;
      margin-right: 30px;
      border:1px solid gray;
      color: gray;
    }
    .changeData-container{
      display: flex;
      width: 80%;
      margin: auto;
      
    }
    .reg-pwd{
      width: 250px;
      padding: 10px 20px;
      margin-right: 30px;
      border-radius: 10px;
    }
    .firstname-input{
      padding: 10px 20px;
      border-radius: 10px;
      margin-right: 30px;
    }
    .state{
      border-radius: 10px;
      border-color: #00f600;
      width: 100px;
      padding: 10px 20px;
      margin-right: 30px;
    }
    .go-register{
      margin-left: -350px;
      padding-left: 270px;
    }
    .nin-reg{
      margin-top: -70px;
    }
    .register-option{
      margin-left: -200px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .my-account-option{
      padding-left: 200px;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: black;
    }
    .my-account-option button{
      color: black;
      background-color: #00f600;
      font-weight: 600;
      padding: 5px 10px;
      width: 110px;
      border-radius: 10px;
      border-color: white;
      margin-bottom: 10px;
    }
    #change-password{
      color: white;
    }
    .register-option button{
      color: black;
      background-color: #00f600;
      padding: 5px 10px;
      width: 110px;
      border-radius: 10px;
      border-color: white;
      margin-bottom: 10px;
    }
    #delete-acount{
      background-color: transparent;
      border-color: red;
      color: red;
      margin-bottom: 50px;
    }
    #sign-out{
      padding: 12px 0px;
    }
    #sign-out-sign-out{
      padding: 12px 0px;
      color: white; 
    }
    .telphone{
      border-radius: 10px;
      border-color: #00f600;
      width: 170px;
      padding: 10px 20px;
      margin-right: 30px;
      /* margin-left: 20px; */
    }
    .title-label{
      margin-left: -40px;
      color: #00f600;
    }
    .title-label-userData{
      margin-left: -40px;
      color: gray;
    }
    .cont-1, .cont-2{
      width: 50%;
      height: 700px;
    }
    .cont-1{
      text-align: center;
      color: white;
    }
    .cont-1-sec{
      width: 100%;
      margin: auto;
      margin-left: 10px;
    }
    .cont-1-sec h2{
      font-size: 35px;
      font-weight: 500;
    }
    .cont-1-sec h3{
      margin-top: -19px;
      font-size: 100px;
      font-weight: 500;
      color: #00f600;
      margin-bottom: -38px;
    }
    /* .failed-nin-verification{
      position: absolute;
      top: 21%;
      background-color: rgba(0, 0, 0, 0.848);
      width: 80%;
      height: 565px;
    } */
    .successful-password{
      position: absolute;
      top: 21%;
      background-color: rgba(0, 0, 0, 0.848);
      width: 80%;
      height: 480px;
    }
    .failed-container{
      padding-top: 55px;
      align-items: center;
      width: 65%;
      margin: auto;
      display: flex;
      gap: 120px;
    }
    .successful-nin-container{
      align-items: center;
      width: 75%;
      margin: auto;
      display: flex;
      gap: 60px;
      padding-top: -20px;
    }
    .successful-signout-user{
      display: flex;
      width: 50%;
      gap: 60px;
      margin: auto;
    }
    .successful-signout-user h3{
      margin-bottom: 60px;
    }
    .successful-signout-user p{
      color: #00f600;
    }
    .successful-nin-container h3{
      font-size: 29px;
      font-weight: 100;
    }
    .successful-nin-container p{
      color: #00f600;
    }
    .failed-sign{
      color: red;
      font-size: 90px;
      margin-top: 140px;
      font-weight: 400;
    }
    .successful-sign, .successful-sign-pwd{
      color: #00f600;
      font-size: 120px;
      margin-top: 140px;
      font-weight: 900;
    }
    .successful-sign-out{
      color: #00f600;
      font-size: 120px;
      margin-top: 70px;
      font-weight: 900; 
    }
    .please-check{
      color: #00f600;
      font-weight: 100;
      font-size: 70px;
      margin-bottom: -25px;
    }
    
    .failed-container h3{
      color: red;
      font-size:25px;
      font-weight: 100;
    }
    .failed-container p{
      color:#00f600;
    }
    .nrc{
      color: #00f600;
      font-size: 15px;
      margin-left: 2px;
    }
    .welcome{
      /* margin-top: -10px; */
      color: #00f600;
      font-weight: 100;
      font-size: 45px;
      margin-bottom: -10px;
    }
    .content{
      width: 60%;
      margin: auto;
      border: 10px solid black;
      background-color: #1f2e3d;
      height: 64%;
      border-radius: 20px;
      text-align: center;
      color: white;
      padding-top: 60px;
    }
    .booking-home-dkt{
      margin-top: -65px;
      display: flex;
      /* gap: 5px; */
      justify-content: center;
      color: #82868a;
      font-size: 15px;
      margin-bottom: 3px;
    }
    .booking-container{
     display: flex;
     flex-direction: column;
     padding: 5px 20px;
      width: 80%;
      margin: auto;
      border:2px solid #00f600;
      border-radius: 20px;
      height: 300px;
      text-align: center;
      background-image: linear-gradient(black, rgba(255, 255, 255, 0.091));
      margin-top: -8px;
      /* margin-bottom: 10px; */
    }
    .booking-container p{
     margin-top: -20px;
    }
    .booking-dkt{
      color: white;
    }
    .choose-time{
      /* width: 250px; */
      padding: 10px 35px;
      font-size: 15px;
      background-color: #00f600;
      color: black;
      border-radius: 10px;
      cursor: pointer;
      margin-bottom: 8px;
    }
    .choose-time:hover{
      background-color: #04d604;
    }
    .choose-time-2{
      padding: 10px 35px;
      font-size: 18px;
      background-color: #00f600;
      color: white;
      border-radius: 10px;
    }
    .choose-time-title{
      display: flex;
      flex-direction: row;
      gap: 17px;
      color: #00f600;
    
      padding-left: 10px;
      font-size: 11px;
      font-weight: 700;
    }
    .pick-tim{
      display:"flex";
      align-items:center;
    }
    .select-passenger{
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0px 10px ;
      /* color:; */
      background-color: rgb(16, 67, 16);
      color: black;
      border-radius: 10px;
    }
    .select-passenger p{
      margin-top: 3px;
      margin-bottom: 8px;
      font-size: 19px;
      font-weight: 600;
    }
    .select-passenger-active{
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0px 10px;
      background-color: #00f600;
      color: white;
      border-radius: 10px;
      border: 1px solid white;
    }
    .select-passenger-active p{
      margin-top: 3px;
      margin-bottom: 8px;
      font-size: 19px;
      font-weight: 600;
    }
    .flex{
      display: flex;
    }
    .add-to-chart{
      /* margin: 2px -10px; */
      padding: 0px 10px 6px 10px;
      margin-right: 20px;
      font-size: 25px;
      border:2px solid #00f600;
      border-radius: 60%;
      color: #00f600;
    }
    .travel-time{
      align-items: center;
      gap: 13px;
      font-size: 12px;
      /* padding-left: 10px; */
      margin-bottom: -35px;
    }
    .travel-time-cont{
      border:2px solid #00f600;
      padding: 5px;
      gap: 15px;
      align-items: center;
      border-radius: 10px;
      margin-bottom: 10px;
    }
    .travel-time-cont button:nth-child(1){
      background-color: #76d6fa;
      font-size: 12px;
      height: 40px;
      padding: 0px 10px;
      cursor: pointer;
      border: none;
      /* margin-right: 5px; */
      border-radius: 5px;
      margin-left: -10px;
    }
    
    .travel-time-cont button:nth-child(2){
      background-color: #eb58ed;
      height: 40px;
      padding: 0px 5px;
      border: none;
      cursor: pointer;
      border-radius: 5px;
    }
    .travel-arrow p{
      padding-top: 10px;
      color: #00f600;
      /* font-size: 178px; */
      font-weight: 10;
      margin-bottom: -5px;
      /* width: 150px; */
    }
    .hide{
      display: none;
    }
    .tim-class{
      color: #00f600;
      font-weight: 750;
      margin-top: -10px;
      margin-bottom: 4px;
      font-size:20px;
    }
    .tim-class-white{
      color: white;
      font-weight: 750;
      margin-top: -10px;
      margin-bottom: 4px;
      font-size:20px; 
    }
    .tim-class-1{
      color: #00f600;
      font-weight:700;
     margin-top:10px;
      font-size:20px;
      /* font-weight: 900; */
      margin-bottom: 4px;
    }
    .tim-class-3{
      color: rgb(16, 67, 16);
      font-weight:700;
     margin-top:-3px;
      font-size:20px;
      /* font-weight: 750; */
      margin-bottom: 4px; 
    }
    .choose-time-container{
      /* padding-top: -120px; */
      padding: 0px 10px;
      background-color: black;
      position: absolute;
      width: 288px;
      margin: auto;
      margin-top: 182px;
      margin-left: -12px;
      z-index: 10000;
      height: 120px;
     
      border: 2px solid white;
      border-radius: 20px;
    }
    .container-list-trv{
      overflow-y: scroll;
      height: 80px;
    }
    .container-list-trv::-webkit-scrollbar{
      width: 5px;
     
    }
    .container-list-trv::-webkit-scrollbar-track{
      color:white ;
    }
    .container-list-trv::-webkit-scrollbar-thumb{
      background-color: rgb(118, 117, 117);
      border-radius: 5px;
      height: 30px;
    }
    .choose-time-container p{
      margin-top: 10px;
    }
    .select-trip{
      margin-top: -40px;
      font-size: 22px;
      margin-bottom: -20px;
      /* -ms-text-kashida-space: 50px; */
    }
    .From{
      background-color: #00f600;
      appearance: none;
      text-align: center;
      cursor: pointer;
      color: black;
      /* user-select: none; */
      font-size: 45px;
      text-transform:uppercase;
      font-weight: 650;
      /* font-family: Montserrat; */
      padding: 4px 10px;
      /* padding: 25px; */
      margin-bottom: 20px;
      border: 1px solid white;
      border-radius: 10px;
      /* color: greenyellow; */
      
    }
    
    
    .dropdownContent{
      position: absolute;
      background-color: black;
      color: #00f600;
      width: 210px;
      margin-left: -9px;
      border-radius: 10px;
      padding: 10px;
      font-size: 19px;
      height: 180px;
      overflow-y: scroll;
      scroll-margin-right: -10px;
      scrollbar-width:thin;
      scrollbar-track-color:transparent;
      scrollbar-base-color: black;
      scroll-snap-type: none;
    }
    .dropdownContent::-webkit-scrollbar{
      background-color: black;
      scroll-margin-right: -10px;
      scrollbar-width:thin;
      scrollbar-track-color:transparent;
      scrollbar-base-color: black;
      scroll-snap-type: none;
      color: black;
    }
    .type-sec{
      font-size:20px;
       font-weight:700;
       margin-bottom: 15px;
       margin-top:5px;
    }
    .setting-policy{
      font-size:18px;
      margin-top:-85px;
      margin-bottom: 30px;
    }
    
    .dropdownItem{
      /* padding: 10px; */
      padding-bottom: 10px;
      cursor: pointer;
      /* transition: all 0.2s; */
    }
    .dropdownItem:hover{
    border:1px solid #00f600;
    border-radius: 10px;
    /* padding: 10px; */
    }
    .From select option:focus-visible{
    
    background-color: green;
    border: 1px solid #00f600;
    }
    select option{
      font-size: 14px;
    }
    
    .selection{
      display: flex;
      flex-direction: column;
      width: 75%;
      margin: auto;
      margin-top: 40px;
    }
    
    .date{
      appearance: none;
      text-align: center;
      text-transform: uppercase;
      font-weight: 650;
      font-size: 40px;
      cursor: pointer;
      color: black;
      background-color: #00f600;
      border: 1px solid white;
      padding: 10px;
      /* margin-bottom: 20px; */
      border-radius: 10px;
    }
    
    .date-update{
      appearance: none;
      text-align: center;
      text-transform: uppercase;
      font-weight: 530;
      font-size: 30px;
      color: white;
      background-color:black;
      border: 1px solid #00f600;
      padding: 10px;
      margin-bottom: 20px;
      border-radius: 10px; 
    }
    input[type="date"]{
      background-color: #00f600;
      font-weight: 400;
      /* -webkit-calendar-picker-indicator:dis */
    }
    input[type="date"]::before{
     background-color: black;
     color: white;
      /* -webkit-calendar-picker-indicator:dis */
    }
    input[type="date"]::-webkit-calendar-picker-indicator {
      /* display: none; */
      width: 15px;
      color: white;}
      
    input[type="date"]:focus{
      background-color: #00f600;
      color: white;
      font-size: 33px;
      /* -webkit-calendar-picker-indicator:dis */
    }
    
    /* input[type="date"]::-webkit-calendar-picker-indicator {
      display: none;
      background-color: greenyellow;
    } */
    .date::placeholder{
      color: black;
      font-weight: 550;
    }
    /* input[type="date"]::-webkit-datetime-edit-text{
      display: none;
      -webkit-ap
    } */
    .payment_procedure{
      width: 80%;
      margin: auto;
      margin-top: 40px;
     
      border:2px solid #00f600;
      border-radius: 20px;
      height: 540px;
      padding: 20px;
    }
    .payment_procedure form{
      width: 60%;
      margin: auto;
      display: grid;
      grid-template-columns: repeat(1, 1fr);
    }
    .payment_procedure form div{
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
    .payment_procedure input{
      background-color: transparent;
      border:2px solid #00f600;
      padding: 20px;
      border-radius: 15px;
      outline: none;
      color: white;
    }
    .payoutGoBtn{
      padding-top: 40px;
    }
    #payment-submit-btn{
      margin-top: 40px;
      font-size: 20px;
      width:140px;
    }
    .sect-go-text{
      color: rgb(2, 84, 2);
      margin-top: -5px;
      margin-bottom:-70px ;
    }
     .selGO-bk2{
      color: rgb(2, 84, 2);
      margin-top: 5px;
      margin-bottom:-30px ;
     }
    .sect-go{
      color: rgb(3, 33, 3);
      margin-top: 40px;
      /* padding-top: -160px; */
      /* margin-top: -42px; */
      font-size: 10px;
      font-weight: bolder;
      /* font-family: Montserrat; */
      cursor: pointer;
      /* overflow: hidden; */
    }
    .sect-go-booking{
      color: rgb(3, 33, 3);
      /* margin-top: 10px; */
      /* padding-top: -160px; */
      /* margin-top: -42px; */
      font-size: 10px;
      font-weight: bolder;
      /* font-family: Montserrat; */
      cursor: pointer;
      /* overflow: hidden; */
    }
    .sect-go1{
      margin-top: -40px;
      color: rgba(7, 69, 5, 0.597);
      font-size: 100px;
      margin-top: -40px;
      cursor: pointer;
    }
    .sect-goCart{
      margin-top: -30px;
      font-size: 150px;
      font-weight: 900;
      cursor: pointer; 
      color:  rgb(16, 67, 16);
    }
    .sect-go2{
      color: #00f600;
      margin-top: -40px;
      margin-top: -40px;
      font-size: 100px;
      cursor: pointer;
    }
    .sect-goactive{
      margin-top: -52px;
      font-size: 150px;
      font-weight: 900;
      cursor: pointer; 
    
      color: #00f600;
    }
    .sect-goactive-buy{
      margin-top: -30px;
      font-size: 150px;
      font-weight: 900;
      cursor: pointer; 
    
      color: #00f600;
    }
    
    #go{
      margin-top: -42px;
      font-size: 150px;
    }
    #home-go{
      margin-top: -42px;
      font-size: 140px;
      cursor: pointer;
    }
    #bookingGO{
      margin-top: -60px;
      /* padding-top: -50px; */
      font-size: 150px;
      font-weight: 900;
      cursor: pointer; 
      color:  rgb(16, 67, 16);
    }
    #goBooking{
      margin-top: -10px;
      font-size: 110px;
      font-weight: 900;
      cursor: pointer; 
      color:  rgb(16, 67, 16);
    }
    #goBookingActive{
      margin-top: -10px;
      font-size: 110px;
      font-weight: 900;
      cursor: pointer; 
      color: #00f600;  
    }
    #bookingGO1{
      margin-top: -52px;
      font-size: 150px;
      font-weight: 900;
      cursor: pointer; 
      color: #00f600; 
    }
    footer{
      position: absolute;
      width: 100%;
      font-size: 20px;
      /* line */
      color: white;
      bottom: 0;
      margin-bottom: 20px;
      text-align: center;
    }
    .wait-container{
      width: 55%;
      margin:auto;
      height: 650px;
      border: 2px solid #00f600;
      border-radius: 20px;
      background-color: black;
    }
    .please-wait{
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 48px;
      font-weight: 100;
    }
    .please-wait span{
      color: #00f600;
      margin-left: 5px;
    }
    .processing-order{
      width: 55%;
      margin: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      color: #00f600;
      font-size: 26px;
      border-radius: 50%;
      border: 2px solid #00f600;
      height: 400px;
    }
    /* mobile */
    .mobileNav{
      display: flex;
      justify-content: space-between;
      background-color: transparent;
      align-items: flex-end;
    }
    .hamburger{
      color: #00f600;
      font-size: 40px;
    }
    .headername{
      color: #00f600;
      font-size: 40px;
    }
    .shopping-cart-mb{
      position: absolute;
      top: 0;
      left: 0;
      width: 80%;
      border-radius: 10px;
      background-image: linear-gradient(black,rgb(83, 81, 81));
      color: white;
      border:2px solid #00f600; 
      padding: 5px 20px;
      margin-top: 10px;
    }
    .shopping-cart-mb div{
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 20px;
    }
    .shopping-cart-mb h4, .information-mb h4, .sign-in-mb h4, .purchase-mb h4{
      color: #00f600;
    }
    .shopping-cart-mb h3, .information-mb h3, .sign-in-mb h3, .purchase-mb h3{
      cursor: pointer;
      color: #82868a;
    }
    .information-mb{
      position: absolute;
      top: 0;
      left: 0;
      width: 80%;
      border-radius: 10px;
      background-image: linear-gradient(black,rgb(83, 81, 81));
      color: white;
      border:2px solid #00f600; 
      padding: 5px 20px;
      margin-top: 10px; 
    }
    .information-mb div, .sign-in-mb div, .purchase-mb div{
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 20px;
    }
    .purchase-mb{
      position: absolute;
      top: 0;
      left: 0;
      width: 80%;
      border-radius: 10px;
      background-image: linear-gradient(black,rgb(83, 81, 81));
      color: white;
      border:2px solid #00f600; 
      padding: 5px 20px;
      margin-top: 10px; 
      padding-bottom: 20px; 
    }
    .sign-in-mb{
      position: absolute;
      top: 0;
      left: 0;
      width: 80%;
      border-radius: 10px;
      background-image: linear-gradient(black,rgb(83, 81, 81));
      color: white;
      border:2px solid #00f600; 
      padding: 5px 20px;
      margin-top: 10px; 
      padding-bottom: 20px;
    }
    .sign-in-mb-form{
      display: flex;
      flex-direction: column;
      padding-bottom: 20px;
    }
    .sign-in-mb-form div{
      display: block;
    }
    .sign-in-mb-form label{
      font-size: 11px;
      font-weight: 700;
      color: #00f600;
    }
    .pls-sign-in-mb{
      margin-top: -10px;
    }
    .sign-in-mb-form input{
      width: 85%;
      padding: 10px 20px;
      border-radius: 7px;
      background-color: #d8dbdd;
    }
    .register-mb{
      width: 100%;
      text-align: center;
      color: black;
      background-color: #00f600;
      padding: 10px;
      font-weight: 500;
      font-size: 18px;
      border-radius: 8px;
    }
    .sign-in-mb-form button{
      margin-top: 10px;
      color: black;
      background-color: #00f600;
      width: 40%;
      padding: 10px;
      border-radius: 8px;
    }
    .active{
      color: #00f600;
    }
    .please-go-desk{
      color: green;
      text-align: center;
    }
    .please-go-payout{
      margin-top: -10px;
      margin-bottom: -10px;
    }
    .select-trip-go-container{
      display: flex;
      width: 90%;
      margin: auto;
      gap: 10px;
    }
    .select-trip-go-container-activ{
      display: flex;
      width: 95%;
      margin: auto;
      margin-top: 20px;
      gap: 10px;
    }
    .to-proceed{
      font-size:12px;
      margin-top:5px;
       margin-bottom :-10px;
    }
    .miniGO{
      font-size:11px;
      margin-top:5px;
       margin-bottom:-10px;
        color:#00f600
  
    }
    .go-shopping-chart{
      display: flex;
      justify-content: center;
      gap: 160px;
    }
    .go-payout{
      display: flex;
      justify-content: center;
      gap: 100px;
    }
    .go-shopping-chart button, .select-trip-go-container button, .go-payout button{
      height: 30px;
      padding:  6px 18px 4px 19px;
      background-color: transparent;
      color: rgb(16, 67, 16);
      margin-top: 55px;
      border-radius: 20px;
      border: 2px solid rgb(16, 67, 16);
      /* margin-top: 10px;  */
    }
    #prev-btn-shopping-chart{
      color: #00f600;
      border-color: #00f600;
      /* margin-top: 45px; */
    }
    
    
    .nav-modal{
      background-image: linear-gradient(black,rgb(83, 81, 81));
      color: white;
      border:2px solid #00f600;
      height: 300px;
      width: 220px;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1000;
      padding-left: -10px;
      margin-right: -30px;
      border-radius: 10px;
    }
    .nav-modal ul{
      margin-left: -25px;
      display: flex;
      flex-direction: column;
      list-style-type: none;
      font-size: 18px;
    }
    .nav-modal ul li{
      margin-bottom: 20px;
    }
    .mobileHome{
      background-image: url('./images/background.png');
      width: 100%;
      height: 100vh;
      background-repeat: no-repeat;
      background-size: cover;
    }
    .headings{
      margin-top: -15px;
      text-align: center;
      color: white;
    }
    .titl-1, .titl-2{
      font-size: 18px;
    }
    .titl-2{
      color: #00f600;
      margin-bottom: -10px;
    }
    .content-mb{
      color: white;
    }
    .content-mb-container {
      margin-top: 40px;
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    }
    .sub-content-mobile{
      width: 90%;
      margin-top: auto;
    }
    .select-trip-mb{
      text-align: center;
      font-size: 18px;
    }
    .form-mb{
      padding-top: 10px;
      display: flex;
      flex-direction: column;
      width: 90%;
      margin: auto;
    }
    .date-mb{
      appearance: none;
      text-align: center;
      text-transform: uppercase;
      font-weight: 530;
      font-size: 40px;
      color: black;
      background-color: #00f600;
      border: 1px solid white;
      padding: 10px;
      margin-bottom: 10px;
      border-radius: 10px;
    }
    .sel-mb{
      margin-top: -10px;
    }
    #go-mb{
      margin-top: -42px;
      font-size: 160px;
    }
    .go-options{
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      width: 100%;
      margin:auto;
    }
    .go-options button{
      height: 30px;
      padding:  6px 20px 4px 23px;
      background-color: transparent;
      color: green;
      border-radius: 20px;
      border: 2px solid green;
      margin-top: 20px;
    }
    .mb-footer{
      font-size: 14px;
      margin-bottom: 5px;
    }
    .booking-mb{
      width: 85%;
      margin: auto;
    }
    .booking-mb-title{
      display: flex;
      align-items: center;
      color: #82868a;
      margin-bottom: -10px;
     
    }
    .booking-mb-title p{
      padding-right: 5px;
    }
    .arrow-left{
      font-size: 14px;
      margin-top: 10px;
    }
    .booking-mb-container{
      background-image: linear-gradient(black,rgb(83, 81, 81));
      text-align: center;
      color: white;
     border-radius: 15px;
      width: 100%;
      margin: auto;
      border:2px solid #00f600;
      height: 300px;
    }
    .booking-bm-go{
      text-align: center;
    }
    .mb-trip{
      margin-top: 4px;
      margin-bottom: -10px;
      font-weight: 400;
      font-size: 25px;
    }
    .mb-date{
      margin-top: -15px;
      margin-bottom: -10px;
    }
    .time-class-mb{
      display: flex;
      flex-direction: column;
    }
    .time-class-mb button{
      width: 90%;
      margin: auto;
      padding: 15px;
      font-size: 18px;
      border-radius: 10px;
      background-color: #00f600;
      /* margin-bottom: 20px; */
    }
    .time-class-mb-table{
      display: none;
      background-color: white;
      color: black;
      width: 75%;
      position: absolute;
      margin:auto;
      margin-left: 20px;
      margin-top: 65px;
    }
    .time-class-mb-table th{
      width: 70px;
    }
    .mb-trip-detail{
      width: 30%;
      margin: auto;
      display:flex;
       gap:10px;
        text-align:center;
    }
    .add-passagers-mb{
      display: flex;
      justify-content: space-between;
      color: black;
      width: 75%;
      margin: auto;
      align-items: center;
      background-color: green;
      padding: 5px 20px;
      border-radius: 10px;
    }
    .add-passagers-mb div{
      font-size: 22px;
      font-weight: 700;
    }
    .increase-btn, .decrease-btn{
      padding: 4px 10px;
      border-radius: 50%;
    }
    .increase-btn:hover, .decrease-btn:hover{
      font-size: 20px;
      color: white;
      background-color: #00f600;
    }
    .go-options .prev-btn-mb{
      border:2px solid #00f600;
      color: #00f600;
    }
    .go-options .prev-btn-mb:hover{
      border:2px solid white;
      background-color: #00f600;
      color: black;
    }
    .totalpriceP{
      margin-left:-60px;
    }
    .totalprice-buyTicket-p{
      margin-left:-70px;
    }
  
}


 /* @media (max-width:2560px){
  .click-Qr-text{
    width: 61%;
    font-size: 23px;
    margin-top: 45px;
  }
  .click-Qr-text-2{
    width: 61%;
    font-size: 23px;
    margin-top: -110px; 
  }
  .ticket-modal{
    width: 30%;
  }
  .ticket-sub-title{
    margin-left: 10px;
    padding-top: 10px;
  }
 } */

 
 /* @media(max-width:1440px){
  .home{
    height: 100vh;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    position: abslute;
  }
 } */